import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useRef, useEffect, useMemo, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../../../aws-exports";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import HeaderBar from "../../../components/HeaderBar/HeaderBar";
import FooterBar from "../../../components/FooterBar/FooterBar";
import AuthCard from "../../../components/Cards/AuthCard/AuthCard";

import "./MessagesMainPage.scss";

import formatTimestamp from "../../../helper/dateFormat";

Amplify.configure(awsExports);
export default function MessagesMainPage() {
  /**
   * Check the language version from HeaderBar componentand update the content to that language
   * EN - English, CN - Chinese
   */
  const [pageLanguage, setPageLanguage] = useState("EN");
  const updatePageLanguageVariable = (languageValue) => {
    setPageLanguage(languageValue);
  };
  const NOTES_API_INVOKE_URL =
    "https://jnhl0s4x28.execute-api.us-east-1.amazonaws.com/dev/messages";
  const [notes, setNotes] = useState([]);
  const [visibleItems, setVisibleItems] = useState(3);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAllNotes();
  }, []);

  /** Get All Note */
  async function getAllNotes() {
    try {
      setLoading(true);
      const response = await fetch(NOTES_API_INVOKE_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
      });
      if (response.ok) {
        const data = await response.text();
        //const result = Object.values(JSON.parse(data));
        const result = JSON.parse(data);
        setTotalItems(result.length);
        setNotes(result);
        console.log("MessagesMainPage.js | getAllNotes() result: ", result);
      } else {
        console.log(
          "MessagesMainPage.js | getAllNotes() request failed with status: ",
          response.status
        );
      }
    } catch (error) {
      // console.log("MessagesMainPage.js | getAllNotes() error: ", error);
    }
  }
  /** GET End */

  /** Get current user start, check auth*/
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        setUserLoggedIn(true);
        setCurrentUser(user.attributes);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log("MessagesMainPage.js | currentUser: ", currentUser);
  /** Get current user end*/

  /**
   * If user logged in, click the input box will lead them to publish page
   * Else, it will pop up the auth card to let them log in
   */
  const [showPopup, setShowPopup] = useState(false);
  const updateShowPopupVariable = (popupVariable) => {
    setShowPopup(popupVariable);
  };

  const handleScroll = () => {
    setLoading(visibleItems < totalItems);

    if (visibleItems < totalItems) {
      setVisibleItems(visibleItems + 5);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [visibleItems, totalItems]);

  const inputRef = useRef(null);

  const handlePostClick = async () => {
    const inputValue = inputRef.current.value;
    if (!inputValue) {
      return; // 如果输入框为空，不执行 POST 请求
    }
    // 创建 POST 请求的数据
    const postData = {
      ID: generateUniqueId(), // 生成唯一的消息ID
      message: inputValue,
      author: currentUser, // 替换为实际用户的名称
    };
    try {
      console.log("Post URL: ", NOTES_API_INVOKE_URL);
      const response = await fetch(NOTES_API_INVOKE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
      if (response.ok) {
        // 发布成功后，清空输入框内容
        inputRef.current.value = "";
        // 获取最新的留言列表
        getAllNotes();
      } else {
        console.log("发布失败，状态码：", response.status);
      }
    } catch (error) {
      console.error("发布时出现错误：", error);
    }
  };

  const handleDeleteClick = async (ID) => {
    try {
      console.log("Delete URL: ", `${NOTES_API_INVOKE_URL}/${ID}`);
      const response = await fetch(`${NOTES_API_INVOKE_URL}/${ID}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        // 删除成功后，刷新留言列表
        getAllNotes();
      } else {
        console.log("删除留言失败，状态码：", response.status);
      }
    } catch (error) {
      console.error("删除留言时出现错误====：", error);
    }
  };

  function generateUniqueId() {
    const timestamp = new Date().getTime(); // Get current timestamp
    const randomPart = Math.floor(Math.random() * 10000); // Generate a random number

    return `${timestamp}-${randomPart}`;
  }
  return (
    <article className="MessagesMainPageContainer">
      <section>
        <HeaderBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          headerLanguage={pageLanguage}
        />
      </section>
      <section className="MessagesMainPageWelcomeContainer"></section>
      {userLoggedIn ? (
        <section className="MessagesMainPagePostArea">
          <div className="MessagesMainPagePost_Wrapper">
            <div className="MessagesMainPagePost_AuthorImage"></div>
            <textarea
              name="discussion"
              placeholder={
                pageLanguage === "EN" ? "Create Post" : "随便说点什么"
              }
              className="MessagesMainPagePost_Input"
              ref={inputRef}
            ></textarea>
            <button
              className="MessagesMainPagePost_Button"
              onClick={handlePostClick}
            >
              {pageLanguage === "EN" ? "Send" : "发送"}
            </button>
          </div>
        </section>
      ) : (
        <></>
      )}

      {notes ? (
        <>
          <section className="MessagesMainPageMainTitle">
            {pageLanguage === "EN" ? "Latest Update" : "最新发布"}
          </section>
          <section className="MessagesMainPageContentsContainer">
            {notes.map((note) => (
              <section className="MessagesMainPageContentWrapper" key={note.ID}>
                <section className="MessagesMainPageFirstLineWrapper">
                  <section className="MessagesMainPageFirstLineWrapper_Left">
                    <div className="MessagesMainPageAuthorImage"></div>
                    <span className="MessagesMainPageAuthorName ">
                      {note.author.nickname}
                    </span>
                    &nbsp;&nbsp;
                    {formatTimestamp(note.timeStamp)}
                  </section>
                  <section className="MessagesMainPageFirstLineWrapper_Left">
                    {currentUser ? (
                      <>
                        {currentUser.sub == note.author.sub ? (
                          <>
                            <span
                              onClick={() => handleDeleteClick(note.ID)}
                              className="MessagesMainPageFirstLineWrapper_DeleteButton"
                            >
                              {pageLanguage === "EN" ? "Delete" : "删除留言"}
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </section>
                </section>
                <section
                  className="MessagesMainPageMessageContentWrapper"
                  dangerouslySetInnerHTML={{
                    __html: note.message.replace(/\n/g, "<br>"),
                  }}
                ></section>
              </section>
            ))}
          </section>
        </>
      ) : (
        <section className="MessagesMainPageLoadingContainer">
          <section className="MessagesMainPageLoadingImageContainer"></section>
        </section>
      )}

      <section className="MessagesMainPageFooterContainer">
        <FooterBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          footerLanguage={pageLanguage}
        />
      </section>
    </article>
  );
}
