import React from "react";

function DesktopFooter() {
  const style = {
    height: "68px",
    width: "90%",
    backgroundColor: "white",
    opacity: 0.75,
    borderRadius: "15px",
    position: "fixed",
    bottom: 0,
    left: "5%",
    zIndex: 1000,
  };

  return <div style={style}>{/* Content of DesktopFooter */}</div>;
}

export default DesktopFooter;
