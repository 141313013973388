// dateUtils.js

// Define an array of month names
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// Export a function to get the current date in the "Month DD, YYYY" format
export function getCurrentFormattedDate() {
  const currentDate = new Date();
  const month = monthNames[currentDate.getMonth()];
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  return `${month} ${formattedDay}, ${year}`;
}
