import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import "./App.scss";

import AboutPage from "./pages/AboutPage/AboutPage";
import AuthPage from "./pages/AuthPage/AuthPage";

import NoteMainPage from "./pages/NotePage/NoteMainPage/NoteMainPage";
import NoteKrenzPage from "./pages/NotePage/NoteKrenzPage/NoteKrenzPage";
import MessagesMainPage from "./pages/MessagesPage/MessagesMainPage/MessagesMainPage";
import LeetcodeNoteContentPage from "./pages/LeetcodeNotePage/LeetcodeNoteContentPage/LeetcodeNoteContentPage";
import SubNoteMenuPage from "./pages/SubNotePage/SubNoteMenuPage/SubNoteMenuPage";
import PublishPage from "./pages/PublishPage/PublishPage";
import EditPage from "./pages/EditPage/EditPage";
import PersonaMainPage from "./pages/PersonaPage/PersonaMainPage/PersonaMainPage";
import PersonaPollPage from "./pages/PersonaPage/PersonaPollPage/PersonaPollPage";

// App 相关链接
import PrivacyPolicyPage from "./pages/App/PrivacyPolicyPage/PrivacyPolicyPage";

// MacOS start
import HomePage from "./pages/macOS/HomePage/HomePage";
function App() {
  /**
   * Use transform scale to fix different screen size
   * For window size: w > h, make pageH = windowH, scale the page
   * For window size: w < h, make pageW = windowW, scale the page
   * The scale rate is different for magazinePage and mobilePage
   */
  var w = window.innerWidth;
  var h = window.innerHeight;
  var magazineStyles;
  var mobileStyles;
  var magazineScaleRate;
  var personaScaleRate;
  //console.log("App | w = ", w, " , h = ", h);
  /**
   * Case 1: w > 0.75h
   */
  if (w >= 0.75 * h) {
    magazineScaleRate = h / 750;
    personaScaleRate = h / 844;
    //console.log("App | w >= h, magazineScaleRate = ", magazineScaleRate);
  } else {
    magazineScaleRate = w / 563;
    personaScaleRate = w / 390;
    //console.log("App | w < h, magazineScaleRate = ", magazineScaleRate);
  }

  /**
   * Case 2: w/h > 390/844
   */

  if (w / h > 0.6) {
    personaScaleRate = h / 844;
    //console.log("App | w/h >= 390/844, personaScaleRate = ", personaScaleRate);
  } else {
    personaScaleRate = w / 390;
    //console.log("App | w/h < 390/844, personaScaleRate = ", personaScaleRate);
  }

  magazineStyles = {
    transform: `scale(${magazineScaleRate})`,
  };

  mobileStyles = {
    transform: `scale(${personaScaleRate})`,
  };

  return (
    <article>
      <Router>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <div>
                <NoteMainPage />
              </div>
            }
          />
          <Route
            path="/notes"
            exact
            element={
              <div>
                <NoteMainPage />
              </div>
            }
          />

          <Route
            path="/about"
            element={
              <div>
                <AboutPage />
              </div>
            }
          />
          <Route
            path="/discussion"
            exact
            element={
              <div>
                <MessagesMainPage />
              </div>
            }
          />
          <Route
            path="/discussion/:problemNumber"
            exact
            element={
              <div>
                <LeetcodeNoteContentPage />
              </div>
            }
          />
          <Route
            path="/discussion/publish"
            exact
            element={
              <div>
                <PublishPage />
              </div>
            }
          />
          <Route
            path="/discussion/edit/:problemNumber"
            exact
            element={
              <div>
                <EditPage />
              </div>
            }
          />
          <Route
            path="/leetcode-note/publish"
            exact
            element={
              <div>
                <PublishPage />
              </div>
            }
          />
          <Route
            path="/leetcode-note/:problemNumber"
            exact
            element={
              <div>
                <LeetcodeNoteContentPage />
              </div>
            }
          />
          <Route
            path="/leetcode-note/edit/:problemNumber"
            exact
            element={
              <div>
                <EditPage />
              </div>
            }
          />
          <Route
            path="/notes/:mainShortenName/:subShortenName/:id"
            exact
            element={
              <div>
                <NoteKrenzPage />
              </div>
            }
          />
          <Route
            path="/notes/:mainShortenName/:subShortenName"
            exact
            element={
              <div>
                <SubNoteMenuPage />
              </div>
            }
          />

          <Route
            path="/auth"
            element={
              <div>
                <AuthPage />
              </div>
            }
          />

          <Route
            path="/phan-site"
            exact
            element={
              <div>
                <PersonaMainPage pageLanguage={0} />
              </div>
            }
          />
          <Route
            path="/phan-site/poll"
            exact
            element={
              <div>
                <PersonaPollPage pageLanguage={0} />
              </div>
            }
          />
          <Route
            path="/phan-site/poll/cn"
            exact
            element={
              <div>
                <PersonaPollPage pageLanguage={1} />
              </div>
            }
          />
          <Route
            path="/phan-site/poll/en"
            exact
            element={
              <div>
                <PersonaPollPage pageLanguage={2} />
              </div>
            }
          />

          {/**MacOS */}
          <Route path="/macOS" element={<HomePage />} />

          {/**MOBILE - APP */}
          <Route path="/app/privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
      </Router>
    </article>
  );
}

export default App;
