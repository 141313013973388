/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:dfef1606-226e-4d4a-b9f6-b70a2a8278ed",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_PDzJphRxE",
    "aws_user_pools_web_client_id": "7gtrifi0ed6kv5ogiqa00qsang",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "chensites-storage-bucket103905-yic",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
