import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Amplify, Auth } from "aws-amplify";
import awsExports from "../../../aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import "./AuthCard.scss";

Amplify.configure(awsExports);
const AuthCard = (props) => {
  const authCardTitle = props.authCardTitle;
  const targetUrl = props.targetUrl;
  const updateShowPopupVariable = props.updateShowPopupVariable;
  const navigate = useNavigate();
  const closeAuthCard = () => {
    updateShowPopupVariable(false);
  };
  return (
    <div className="popup">
      <div className="popup-content">
        <article className="AuthCardContainer">
          <section className="AuthCardCloseButtonRow">
            <FontAwesomeIcon icon={faXmark} onClick={closeAuthCard} />
          </section>
          <section>{authCardTitle}</section>

          <Authenticator signUpAttributes={["email", "nickname"]}>
            {() => navigate(targetUrl)}
          </Authenticator>
        </article>
      </div>
    </div>
  );
};

export default AuthCard;
