import React, { useState, useEffect, useRef } from "react";
import "./DesktopHeader.scss";

function DesktopHeader({ openNoteWindow, handleNoteChange }) {
  const [currentTime, setCurrentTime] = useState("");
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [activeItem, setActiveItem] = useState("");
  const fileMenuRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleMenuItemClick = (noteTitle) => {
    openNoteWindow(noteTitle);
    setIsMenuVisible(false); // Close the menu after opening the window
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const timeString = now.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      setCurrentTime(now.toDateString().substr(0, 10) + " " + timeString);
    }, 1000);

    // 点击事件监听器
    const handleClickOutside = (event) => {
      if (
        fileMenuRef.current &&
        dropdownRef.current &&
        !fileMenuRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      clearInterval(intervalId);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMenuClick = (item) => {
    setActiveItem(item);
    setIsMenuVisible(item === "文件" ? !isMenuVisible : false);

    if (item === "文件" && fileMenuRef.current && !isMenuVisible) {
      const rect = fileMenuRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.bottom,
        left: rect.left,
      });
    }
  };

  return (
    <div className="desktopHeader">
      <div className="menu">
        <span
          ref={fileMenuRef}
          onClick={() => handleMenuClick("文件")}
          className={activeItem === "文件" ? "active" : ""}
        >
          文件
        </span>
        <span
          onClick={() => handleMenuClick("编辑")}
          className={activeItem === "编辑" ? "active" : ""}
        >
          编辑
        </span>
        <span
          onClick={() => handleMenuClick("留言板")}
          className={activeItem === "留言板" ? "active" : ""}
        >
          留言板
        </span>
        <span
          onClick={() => handleMenuClick("帮助")}
          className={activeItem === "帮助" ? "active" : ""}
        >
          帮助
        </span>
      </div>
      <span className="time">{currentTime}</span>
      {isMenuVisible && (
        <div
          className="dropdown"
          ref={dropdownRef}
          style={{ left: `${menuPosition.left}px`, display: "block" }}
        >
          <div
            className="menuItem"
            onClick={() => {
              handleNoteChange("krenz");
              setIsMenuVisible(false);
            }}
          >
            Krenz听课笔记
          </div>
          <div
            className="menuItem"
            onClick={() => {
              handleNoteChange("leetcode");
              setIsMenuVisible(false);
            }}
          >
            Leetcode做题笔记
          </div>
          <div
            className="menuItem"
            onClick={() => {
              handleNoteChange("travel");
              setIsMenuVisible(false);
            }}
          >
            旅行笔记
          </div>
        </div>
      )}
    </div>
  );
}

export default DesktopHeader;
