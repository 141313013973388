import React, { useState } from "react";
import DesktopFooter from "../../../components/macOS/DesktopFooter/DesktopFooter";
import DesktopHeader from "../../../components/macOS/DesktopHeader/DesktopHeader";
import FlexibleWindow from "../../../components/macOS/FlexibleWindow/FlexibleWindow";
import "./HomePage.scss";

function HomePage() {
  const [activeWindow, setActiveWindow] = useState(null);

  const [activeNote, setActiveNote] = useState(null);

  const handleNoteChange = (noteType) => {
    setActiveNote(noteType);
  };

  return (
    <div className="outerStyle">
      <DesktopHeader handleNoteChange={handleNoteChange} />

      {/* 4. Conditional rendering of FlexibleWindow */}
      {activeNote === "krenz" && <FlexibleWindow noteTitle="Krenz听课笔记" />}
      {activeNote === "leetcode" && (
        <FlexibleWindow noteTitle="Leetcode做题笔记" />
      )}
      {activeNote === "travel" && <FlexibleWindow noteTitle="旅行笔记" />}
      <DesktopFooter />
      <div className="backgroundStyle" />
    </div>
  );
}

export default HomePage;
