import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../../../aws-exports";

import Switch from "@mui/material/Switch";

import logo_white from "../../../img/Logo/logo_white.svg";
import AnalyzeChart from "../../AnalyzeChart/AnalyzeChart";

import "./WideFooterBar.scss";

const WideFooterBar = (props) => {
  const updatePageLanguageVariable = props.updatePageLanguageVariable;
  const footerLanguage = props.footerLanguage;
  /**
   * Switch Toggle For Language
   */
  const switchEN = () => {
    updatePageLanguageVariable("EN");
  };
  const switchCN = () => {
    updatePageLanguageVariable("CN");
  };
  const switchLanguage = (event) => {
    if (event.target.checked) {
      // Function to execute when the Switch is checked
      switchEN();
    } else {
      // Function to execute when the Switch is unchecked
      switchCN();
    }
  };
  return (
    <article className="WideFooterBarContainer ">
      <section className="WideFooterBarContainerTopWrapper">
        <section className="WideFooterBarContainerTop">
          <Link to={`/notes`} className="WideFooterBarContainerTopText">
            {footerLanguage === "EN" ? "NOTES" : "笔记"}
          </Link>
          <Link to={`/discussion`} className="WideFooterBarContainerTopText">
            {footerLanguage === "EN" ? "DISCUSSION" : "尽情吐槽区"}
          </Link>
          <Link to={`/phan-site`} className="WideFooterBarContainerTopText">
            {footerLanguage === "EN" ? "PHAN-SITE" : "怪盗频道"}
          </Link>
          <Link to={`/about`} className="WideFooterBarContainerTopText">
            {footerLanguage === "EN" ? "ABOUT ME" : "关于我"}
          </Link>
        </section>
        <section>
          <span>中文</span>
          <Switch
            defaultChecked
            onChange={switchLanguage}
            size="small"
            color="default"
          />
          <span>EN</span>
        </section>
      </section>

      <section className="WideFooterBarContainerBottom">
        <img src={logo_white} alt="logo_white"></img>
        <span className="WideFooterBarContainerBottomCopyRight">
          &#169; 2023 Chensites. Studio
        </span>
      </section>
    </article>
  );
};

export default WideFooterBar;
