import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Unstable_Grid2";

import "./TravelNoteCards.scss";
import TravelNoteCard from "./TravelNoteCard/TravelNoteCard";
import NOTE_DATA from "../../../data/notes/travelNotes.json";
const TravelNoteCards = (props) => {
  const pageLanguage = props.pageLanguage;
  const data = useMemo(() => NOTE_DATA);
  // Function to chunk the data array into groups of 'size'
  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  const rowsOfThree = chunkArray(data, 3);

  return (
    <article className="TravelNoteCardsContainer">
      {rowsOfThree.map((row, rowIndex) => {
        // Calculate the number of empty spaces needed for each row
        const emptySpaces = Array(3 - row.length).fill(1);

        return (
          <section key={rowIndex} className="TravelNoteCardsRowWrapper">
            {row.map((travelNote_item, itemIndex) => (
              <div key={itemIndex} className="TravelNoteCardsItemWrapper">
                <TravelNoteCard
                  pageLanguage={pageLanguage}
                  data={travelNote_item}
                />
              </div>
            ))}

            {/* Render empty spaces to justify content when there are less than 3 items */}

            {emptySpaces.map((emptyItem) => (
              <div
                key={emptyItem}
                className="TravelNoteCardsItemWrapper emptySpace"
              >
                <TravelNoteCard />
              </div>
            ))}
          </section>
        );
      })}
    </article>
  );
};

export default TravelNoteCards;
