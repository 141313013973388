import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet"; // Import Leaflet library
import "leaflet/dist/leaflet.css";
import "./MapCard.scss";
import TRAVEL_DATA from "../../../data/notes/travelNotes.json";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";

const customMarkerIcon = new L.Icon({
  iconUrl: "../../../img/Icon/marker.png",
  iconSize: [32, 32], // Set the size of the icon
  iconAnchor: [16, 32], // Set the position to anchor the icon (should be half of iconSize)
  popupAnchor: [0, -32], // Set the position to open the popup (relative to the icon anchor)
});

const MapCard = () => {
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    setMapLoaded(true);
  }, []);

  return (
    <div className="MapCardContainer">
      <div className="MapWrapper" style={{ width: "100%", height: "100%" }}>
        {mapLoaded ? (
          <MapContainer
            center={[40.7128, -74.006]}
            zoom={4}
            style={{ width: "100%", height: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {TRAVEL_DATA.map((item) => (
              <Marker
                key={item.id}
                position={[item.address[0].lat, item.address[0].lng]}
                icon={
                  new Icon({
                    iconUrl: markerIconPng,
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                  })
                }
              >
                <Popup>{item.mainTitle_CN}</Popup>
              </Marker>
            ))}
          </MapContainer>
        ) : (
          <div className="map-loading">Loading Map...</div>
        )}
      </div>
      {/*<div className="MapTipWrapper">some tips text</div>*/}
    </div>
  );
};

export default MapCard;
