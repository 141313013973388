import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useId, useRef, useEffect, useMemo, useState } from "react";

import { v4 } from "uuid";

import ReactQuill from "react-quill";
import Select from "react-select";

import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../../aws-exports";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import HeaderBar from "../../components/HeaderBar/HeaderBar";
import FooterBar from "../../components/FooterBar/FooterBar";

import InputBoxWithDropdown from "../../components/InputBoxWithDropdown/InputBoxWithDropdown";

import "./PublishPage.scss";

import CODE_DATA from "../../data/notes/leetcodeNotes";

export default function PublishPage() {
  /**
   * Check the language version from HeaderBar componentand update the content to that language
   * EN - English, CN - Chinese
   */
  const [pageLanguage, setPageLanguage] = useState("EN");
  const updatePageLanguageVariable = (languageValue) => {
    setPageLanguage(languageValue);
  };
  /** Get current user start*/
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        setUserLoggedIn(true);
        setUserName(user.attributes.nickname);
        setUserId(user.attributes.sub);
      })
      .catch((err) => console.log(err));
  }, []);
  /** Get current user end*/
  const navigate = useNavigate();
  const NOTES_API_INVOKE_URL =
    "https://jnhl0s4x28.execute-api.us-east-1.amazonaws.com/dev/notes";
  const modules = {
    toolbar: [
      // options here
      ["bold", "strike"], // toggled buttons
      ["code-block"],
      [{ list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      ["clean"],
    ],
  };

  const [problemNumber, setProblemNumber] = useState("");
  const [problemLink, setProblemLink] = useState("");
  const [problemTitle, setProblemTitle] = useState("");
  const [problemTag, setProblemTag] = useState([]);
  const [submitTime, setSubmitTime] = useState(null);
  const [description, setDescription] = useState("");

  const handleProblemNumberChange = (event) => {
    setProblemNumber(event.target.value.toString());
  };
  const handleProblemLinkChange = (event) => {
    setProblemLink(event.target.value.toString());
  };
  const handleTitleChange = (event) => {
    setProblemTitle(event.target.value.toString());
  };
  const handleTagChange = (selectedOptions) => {
    setProblemTag(selectedOptions);
    console.log("PublishPage.js | Selected options:", selectedOptions);
    // 进行其他处理
  };
  const handleSubmitTimeChange = (date) => {
    setSubmitTime(date);
  };
  const handleDescriptionChange = (value) => {
    setDescription(value); // Update the state with the editor content
  };
  const handleSave = () => {
    publishNote();
    alert("Published");
    navigate("/notes"); // CV0-43 change to navigate to note page
  };

  /**
   *
   * @param {*} selectedOption
   */
  const [postType, setPostType] = useState("Others");
  const handleOptionSelect = (selectedOption) => {
    console.log("Selected option:", selectedOption);
    setPostType(selectedOption);
    // Perform any additional actions with the selected option
  };

  const [notes, setNotes] = useState([]);
  useEffect(() => {
    getAllNotes();
  }, []);

  /** Get All Note */
  async function getAllNotes() {
    try {
      const response = await fetch(NOTES_API_INVOKE_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
      });
      if (response.ok) {
        const data = await response.text();
        const result = JSON.parse(data);
        setNotes(result);
      } else {
        console.log(
          "MessagesMainPage.js | getAllNotes() request failed with status: ",
          response.status
        );
      }
    } catch (error) {
      // console.log("MessagesMainPage.js | getAllNotes() error: ", error);
    }
  }
  /** GET End */
  //const noteId = useId();

  /** Publish a Note */
  async function publishNote() {
    // console.log("PublishPage.js | publishNote() running ......");
    const now = new Date().toISOString();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      id: v4(),
      noteType: postType,
      title: problemTitle,
      problemNumber: problemNumber,
      problemLink: problemLink,
      problemTag: problemTag,
      date: submitTime,
      updateTime: now,
      description: description,
      author: userName,
      authorId: userId,
    });
    try {
      const response = await fetch(NOTES_API_INVOKE_URL, {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      });
      const result = JSON.parse(await response.text()).body;
      console.log("PublishPage.js | publishNote() result: ", result);
    } catch (error) {
      console.log("error", error);
    }
  }
  /** Publish End */

  return (
    <article className="PublishPageContainer">
      <section>
        <HeaderBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          headerLanguage={pageLanguage}
        />
      </section>
      {userLoggedIn ? (
        <section className="PublishPageBodyContainer">
          <section className="PublishPageLogoContainer" id="Logo">
            {pageLanguage === "EN" ? "Create A Post" : "发布文章"}
          </section>

          <section className="PublishPageTypeRow">
            <div className="PublishPageColumn">
              <section className="PublishPageTitleContainer">
                <div className="PublishPageInputTitleContainer">
                  {pageLanguage === "EN" ? "Post Area *" : "发布板块 *"}
                </div>
                <InputBoxWithDropdown
                  className="PublishPageInputContainer"
                  onOptionSelect={handleOptionSelect}
                />
              </section>
            </div>
          </section>
          <section className="PublishPageTitleContainer" id="Title">
            <div className="PublishPageInputTitleContainer">
              {pageLanguage === "EN" ? "Post Title *" : "文章标题 *"}
            </div>
            <input
              name="title"
              type="string"
              onChange={handleTitleChange}
              className="PublishPageInputContainer"
            />
          </section>
          <section>
            <div className="PublishPageInputTitleContainer">
              {pageLanguage === "EN" ? "Tags" : "标签"}
            </div>
            <Select
              isMulti
              name="colors"
              options={CODE_DATA}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleTagChange}
              value={problemTag}
            />
          </section>
          <section className="PublishPageTypeRow">
            {postType === "Leetcode Solution" ? (
              <>
                <div className="PublishPageColumn">
                  <section className="PublishPageTitleContainer">
                    <div className="PublishPageInputTitleContainer">
                      {pageLanguage === "EN"
                        ? "Problem Number"
                        : "Leetcode问题序号"}
                    </div>
                    <input
                      name="number"
                      type="string"
                      onChange={handleProblemNumberChange}
                      className="PublishPageInputContainer"
                    />
                  </section>
                </div>
                <div className="PublishPageColumn">
                  <section className="PublishPageTitleContainer">
                    <div className="PublishPageInputTitleContainer">
                      {pageLanguage === "EN"
                        ? "Problem Link on Leetcode"
                        : "Leetcode问题链接"}
                    </div>
                    <input
                      name="number"
                      type="string"
                      onChange={handleProblemLinkChange}
                      className="PublishPageInputContainer"
                    />
                  </section>
                </div>
                <div className="PublishPageColumn">
                  <section className="PublishPageTitleContainer">
                    <div className="PublishPageInputTitleContainer">
                      {pageLanguage === "EN" ? "Submit Time" : "提交时间"}
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="time"
                        selected={submitTime}
                        onChange={handleSubmitTimeChange}
                        className="PublishPageInputContainer"
                      />
                    </LocalizationProvider>
                  </section>
                </div>
              </>
            ) : (
              <></>
            )}
          </section>

          <section className="PublishPageTitleContainer">
            <div className="PublishPageInputTitleContainer">
              {pageLanguage === "EN" ? "Description" : "文章内容"}
            </div>
            <div>
              <ReactQuill
                modules={modules}
                theme="snow"
                value={description}
                onChange={handleDescriptionChange}
                className="CustomizeReactQuill"
              />
            </div>
          </section>

          <section className="PublishPageSubmitButtonContainer">
            <button onClick={handleSave} className="PublishPageSubmitButton">
              {pageLanguage === "EN" ? "PUBLISH" : "发 布"}
            </button>
          </section>
        </section>
      ) : (
        <section className="PublishPageBodyContainer">Please Log In</section>
      )}

      <section className="PublishPageFooterContainer">
        <FooterBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          footerLanguage={pageLanguage}
        />
      </section>
    </article>
  );
}
