import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import "./TravelNoteCard.scss";

const TravelNoteCard = (props) => {
  const pageLanguage = props.pageLanguage;
  const data = props.data;
  console.log("TravelNoteCard | data: ", data);
  return (
    <article className="TravelNoteCardContainer">
      <section className="TravelNoteCardImageWrapper">
        <img
          src={data?.coverImageURL} // Replace with the actual path to your image
          alt="Travel Note Image"
          className="TravelNoteImage"
        />
      </section>
      <section className="TravelNoteCardTitleWrapper">
        <div className="TravelNoteCardSubTitleI">
          {data?.address[0]?.City}, {data?.address[0]?.State},{" "}
          {data?.address[0]?.Country}
        </div>
        <div className="TravelNoteCardMainTitle">
          <a href={data?.noteLink} target="_blank">
            {pageLanguage === "EN" ? data?.mainTitle_EN : data?.mainTitle_CN}
          </a>
        </div>
        <div className="TravelNoteCardSubTitleII">{data?.visitDate}</div>
      </section>
      <section className="TravelNoteCardInfoWrapper">
        {pageLanguage === "EN" ? data?.brief_EN : data?.brief_CN}
      </section>
      <section className="TravelNoteCardBtn"></section>
    </article>
  );
};

export default TravelNoteCard;
