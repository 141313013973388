import React, { useEffect, useRef, useState } from "react";
import "./AboutPage.scss";
import AboutLeftPage from "./AboutLeftPage/AboutLeftPage";
import AboutRightPage from "./AboutRightPage/AboutRightPage";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import WideFooterBar from "../../components/FooterBar/WideFooterBar/WideFooterBar";

export default function AboutPage() {
  /**
   * Check the language version from HeaderBar componentand update the content to that language
   * EN - English, CN - Chinese
   */
  const [pageLanguage, setPageLanguage] = useState("EN");
  const updatePageLanguageVariable = (languageValue) => {
    setPageLanguage(languageValue);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mobileView, setMobileView] = useState(window.innerWidth < 955);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 955) {
        setMobileView(false);
      } else {
        setMobileView(true);
      }
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  var scaleSize = mobileView
    ? (windowWidth * 0.9) / 895
    : (windowWidth * 0.9) / 1790;
  var scaledHeight = 1100 * scaleSize;
  var scaleStyles;
  scaleStyles = {
    transform: `scale(${scaleSize})`,
  };
  return (
    <article className="AboutPageContainer">
      <HeaderBar
        updatePageLanguageVariable={updatePageLanguageVariable}
        colorTheme={0}
        headerLanguage={pageLanguage}
      />

      <section className="AboutPageBodyContainer">
        <section
          className="AboutPageLeftContainer"
          style={{ height: scaledHeight }}
        >
          <div style={scaleStyles}>
            <AboutLeftPage pageLanguage={pageLanguage === "EN" ? 2 : 1} />
          </div>
        </section>
        <section
          className="AboutPageRightContainer"
          style={{ height: scaledHeight }}
        >
          <div style={scaleStyles}>
            <AboutRightPage pageLanguage={pageLanguage === "EN" ? 2 : 1} />
          </div>
        </section>
      </section>
      <section className="AboutPageFooterContainer">
        <FooterBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          footerLanguage={pageLanguage}
        />
      </section>
    </article>
  );
}
