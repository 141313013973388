import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
export default function RadioChart() {
  var option = {
    title: {
      text: "CHENCHEN SKILL SET",
      show: false,
    },
    legend: {
      data: ["ChenChen Skill"],
      show: false,
    },
    radar: {
      indicator: [
        {
          name: "JavaScript",
          max: 10,
        },
        {
          name: "ReactJS",
          max: 10,
        },
        {
          name: "Python",
          max: 10,
        },
        {
          name: "HTML/CSS",
          max: 10,
        },
        {
          name: "GitHub",
          max: 10,
        },
        {
          name: "Angular",
          max: 10,
        },
      ],
      axisName: {
        fontSize: "2vw",
        fontStyle: "normal",
        fontWeight: "500",
        color: "black",
        backgroundColor: "#DDDACF",
        padding: [
          2, -20, 2, -20,
        ] /** SMART! Change axisName position by padding instead of nameGap */,
        textBorderColor: "#DDDACF",
        textBorderWidth: 8,
      },
      radius: ["0%", "80%"],
      nameGap: 0,
      splitArea: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: "black",
        },
      },
      splitLine: {
        lineStyle: {
          width: 1,
          color: [
            "black",
            "transparent",
            "transparent",
            "transparent",
            "transparent",
          ],
        },
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        name: "Chen Chen Skill Radar",
        type: "radar",
        data: [
          {
            value: [8, 8, 7, 10, 9, 6],
            name: "ChenChen Skill",
          },
        ],
        itemStyle: {
          color: "gray",
        },
        lineStyle: {
          color: "transparent",
        },
        areaStyle: {
          opacity: 0.5,
        },
        symbolSize: 0,
      },
    ],
  };

  return (
    <ReactECharts option={option} style={{ width: "100%", height: "100%" }} />
  );
}
