import { React, useState, useEffect } from "react";
import axios from "axios";
import "./PersonaMainPage.scss";
import { Link } from "react-router-dom";
import title_svg from "../../../img/PersonaPage/PollAnswer/PollPageTitle.svg";
import answerA_svg from "../../../img/PersonaPage/PollAnswer/PollPageYIcon.svg";
import anserYes_svg from "../../../img/PersonaPage/PollAnswer/PollPageYellowYes.svg";
import anserPercentage_svg from "../../../img/PersonaPage/PollAnswer/PollPagePercentageIcon.svg";
import question_J from "../../../img/PersonaPage/PollQuestion/PollPageQuestion.svg";
import question_C from "../../../img/PersonaPage/PollQuestion/PollPageQuestion_C.svg";
import question_E from "../../../img/PersonaPage/PollQuestion/PollPageQuestion_E.svg";
import yes_svg from "../../../img/PersonaPage/PollOptions/Yes.svg";
import no_svg from "../../../img/PersonaPage/PollOptions/No.svg";
import phan_logo from "../../../img/PersonaPage/Logos/phanch_logo.png";
import PersonaLoadingPage from "../PersonaLoadingPage/PersonaLoadingPage";

export default function PersonaMainPage(props) {
  /**
   * If window width larger than 955, display the page in center
   * Else, fulfuill the page
   */
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [wideWindow, setWideWindow] = useState(window.innerWidth >= 955);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 955) {
        setWideWindow(true);
      } else {
        setWideWindow(false);
      }
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  var scaleSize = wideWindow ? 1 : windowWidth / 390;
  var scaledHeight = 844 * scaleSize;
  var scaleStyles;
  scaleStyles = {
    transform: `scale(${scaleSize})`,
  };

  const [voteResult, setVoteResult] = useState();
  const [countYES, setCountYES] = useState();
  const [countNO, setCountNO] = useState();
  const [pageLoading, setPageLoading] = useState(true);
  //const pageLanguage = props.pageLanguage; // 0 - Japanese, 1 - Chinese, 2 - English
  const [pageLanguage, setPageLanguage] = useState(0);
  function jaVersion() {
    setPageLanguage(0);
  }
  function cnVersion() {
    setPageLanguage(1);
  }
  function enVersion() {
    setPageLanguage(2);
  }
  const [redWidth, setRedWidth] = useState(
    "M2647.164,5085.05l150.955-10.872v25.014H2656.08Z"
  );
  const POLLS_API_INVOKE_URL =
    "https://jnhl0s4x28.execute-api.us-east-1.amazonaws.com/dev/polls";

  useEffect(() => {
    UpdatePollData();
  }, [countYES, countNO, voteResult, redWidth]);
  /** GET POLL RESULT */
  function UpdatePollData() {
    console.log("PersonaPollPage.js | UpdatePollData() running ......");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // Create Promise objects for the two API calls
    const promiseYES = fetch(
      POLLS_API_INVOKE_URL + "?countType=userSelection&value=YES",
      {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setCountYES(parseInt(data["count"]));
      })
      .catch((error) => console.log("error", error));
    const promiseNO = fetch(
      POLLS_API_INVOKE_URL + "?countType=userSelection&value=NO",
      {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setCountNO(parseInt(data["count"]));
      })
      .catch((error) => console.log("error", error));
    // Use Promise.all() to wait for both API calls to complete
    Promise.all([promiseYES, promiseNO]).then(() => {
      // Calculate the percentage
      const countTOTAL = countYES + countNO;
      let percentage = (countYES / countTOTAL) * 100;
      percentage = percentage.toFixed(1);
      setVoteResult(percentage);

      // Calculate the Redbar
      let pollBarRed =
        "M2647.164,5085.05l" +
        Math.floor(2.3 * voteResult) +
        ".955-10.872v25.014H2656.08Z";
      setRedWidth(pollBarRed);
    });
    if (voteResult > 0) {
      setTimeout(() => {
        setPageLoading(false);
      }, 1500);
    }
  }
  /** GET POLL RESULT   ================== END */

  return (
    <>
      {pageLoading ? (
        <PersonaLoadingPage />
      ) : (
        <article
          className="PersonaPageContainer"
          style={{ height: scaledHeight }}
        >
          <div style={scaleStyles}>
            <div className="PersonaMainPageContainer">
              <section className="BodyContainer ">
                <section className="PageHeader">
                  <div className="PollPageTitleContainer">
                    <img src={title_svg} alt="Title" />
                  </div>
                  <div className="PollPageQuestionContainer">
                    {pageLanguage === 0 ? (
                      <img src={question_J} alt="Question_J" />
                    ) : (
                      <>
                        {pageLanguage === 1 ? (
                          <img src={question_C} alt="Question_C" />
                        ) : (
                          <img src={question_E} alt="Question_E" />
                        )}
                      </>
                    )}
                  </div>
                </section>

                <section className="PageHeader">
                  <div className="PollPageResultContainer">
                    <div className="AnswerIconsWrapper">
                      <div className="AnswerAIcon">
                        <img src={answerA_svg} alt="answerA"></img>
                      </div>
                      <div className="AnswerYesIcon">
                        <img src={anserYes_svg} alt="answerYes"></img>
                      </div>
                    </div>

                    <div className="AnswerResultWrapper">
                      <div className="AnswerText">{voteResult}</div>
                      <div className="AnswerPercentageIcon">
                        <img
                          src={anserPercentage_svg}
                          alt="answerPercentage"
                        ></img>
                      </div>
                    </div>
                  </div>
                  <section className="PollWrapper">
                    <div className="button-style yes-button">
                      <img src={yes_svg} alt="yes_svg" />
                    </div>
                    <div className="PollBarContainer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="266.622"
                        height="57.61"
                        viewBox="0 0 266.622 57.61"
                      >
                        <g
                          id="Poll_Container"
                          transform="translate(-32.415 -403.721)"
                        >
                          <path
                            id="gray_area"
                            d="M2647.164,5085.05l226.556-21.139v35.28H2656.08Z"
                            transform="translate(-2594 -4650)"
                            opacity="0.395"
                          />
                          <path
                            id="red_area"
                            d={redWidth}
                            transform="translate(-2594 -4650)"
                            fill="red"
                          />
                          <g
                            id="white_line"
                            transform="translate(-2594 -4650)"
                            fill="none"
                          >
                            <path
                              d="M2647.164,5085.05l230.743-18.105-5.989,27.744-215.838,4.5Z"
                              stroke="none"
                            />
                            <path
                              d="M 2656.07958984375 5099.19189453125 L 2871.917724609375 5094.68896484375 L 2877.90625 5066.94482421875 L 2647.16357421875 5085.05029296875 L 2656.07958984375 5099.19189453125 M 2649.546875 5111.3310546875 L 2626.4150390625 5074.6416015625 L 2893.037109375 5053.720703125 L 2881.647216796875 5106.48876953125 L 2649.546875 5111.3310546875 Z"
                              stroke="none"
                              fill="#fff"
                            />
                          </g>
                          <g
                            id="black_line"
                            transform="translate(-2594 -4650)"
                            fill="none"
                          >
                            <path
                              d="M2647.164,5085.05l226.351-13.07.205-.012-2.442,21.965-215.2,5.258Z"
                              stroke="none"
                            />
                            <path
                              d="M 2873.7197265625 5071.96875 L 2873.514404296875 5071.98046875 L 2647.16357421875 5085.05029296875 L 2656.07958984375 5099.19189453125 L 2871.27734375 5093.93408203125 L 2873.7197265625 5071.96875 M 2879.343017578125 5066.63916015625 L 2875.76416015625 5098.826171875 L 2871.3994140625 5098.9326171875 L 2653.364013671875 5104.259765625 L 2638.41357421875 5080.54736328125 L 2646.875244140625 5080.05859375 L 2873.4345703125 5066.97705078125 L 2879.343017578125 5066.63916015625 Z"
                              stroke="none"
                              fill="#000"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="button-style no-button">
                      <img src={no_svg} alt="no_svg" />
                    </div>
                  </section>
                  <section className="thanksNote">
                    {pageLanguage === 0 ? (
                      <>
                        ご覧かありがとうございました。
                        <br /> 覧のアンケートにもご覧下さい。
                      </>
                    ) : (
                      <>
                        {pageLanguage === 1 ? (
                          <>
                            感謝您選擇我們的網站， 請填寫
                            <br />
                            我們的調查問卷， 分享您的想法。
                          </>
                        ) : (
                          <>
                            Thank you for choosing our website. <br />
                            Please take our survey.
                          </>
                        )}
                      </>
                    )}
                  </section>

                  <section className="moreButtonsWrapper">
                    <Link to="/">
                      <button className="svg-button">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="140.705"
                          height="47.063"
                          viewBox="0 0 140.705 47.063"
                        >
                          <g id="TOP" transform="translate(-29.645 -602.451)">
                            <path
                              id="button_2"
                              d="M3096.706,5105.7l128.391-6.957-11.8,46.026h-108.18l-19.414-29.588Z"
                              transform="translate(-3055.401 -4495.757)"
                              stroke="#000"
                              strokeWidth="1"
                            />
                            <text
                              id="TOPに戻る"
                              transform="translate(100 632)"
                              fill="#fff"
                              font-size="14"
                              font-weight="400"
                            >
                              <tspan x="-20%" y="0">
                                {pageLanguage === 0 ? (
                                  <>TOPに戻る</>
                                ) : (
                                  <>
                                    {pageLanguage === 1 ? (
                                      <>返回頂部</>
                                    ) : (
                                      <>Back to Top</>
                                    )}
                                  </>
                                )}
                              </tspan>
                            </text>
                          </g>
                        </svg>
                      </button>
                    </Link>

                    <>
                      {pageLanguage === 0 ? (
                        <Link to="/phan-site/poll">
                          <button className="svg-button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="140.705"
                              height="47.063"
                              viewBox="0 0 140.705 47.063"
                            >
                              <g
                                id="SURVEY"
                                transform="translate(-213.83 -599.91)"
                              >
                                <path
                                  id="button_2"
                                  d="M3096.706,5105.7l128.391-6.957-11.8,46.026h-108.18l-19.414-29.588Z"
                                  transform="translate(-2871.216 -4498.297)"
                                  stroke="#000"
                                  strokeWidth="1"
                                />
                                <text
                                  id="アンケートに関る"
                                  transform="translate(286 629)"
                                  fill="#fff"
                                  font-size="13"
                                  font-weight="400"
                                >
                                  <tspan x="-40%" y="0">
                                    アンケートに関する
                                  </tspan>
                                </text>
                              </g>
                            </svg>
                          </button>
                        </Link>
                      ) : (
                        <>
                          {pageLanguage === 1 ? (
                            <Link to="/phan-site/poll/cn">
                              <button className="svg-button">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="140.705"
                                  height="47.063"
                                  viewBox="0 0 140.705 47.063"
                                >
                                  <g
                                    id="SURVEY"
                                    transform="translate(-213.83 -599.91)"
                                  >
                                    <path
                                      id="button_2"
                                      d="M3096.706,5105.7l128.391-6.957-11.8,46.026h-108.18l-19.414-29.588Z"
                                      transform="translate(-2871.216 -4498.297)"
                                      stroke="#000"
                                      strokeWidth="1"
                                    />
                                    <text
                                      id="アンケートに関る"
                                      transform="translate(286 629)"
                                      fill="#fff"
                                      font-size="13"
                                      font-weight="400"
                                    >
                                      <tspan x="-20%" y="0" fontSize={14}>
                                        問卷相關
                                      </tspan>
                                    </text>
                                  </g>
                                </svg>
                              </button>
                            </Link>
                          ) : (
                            <Link to="/phan-site/poll/en">
                              <button className="svg-button">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="140.705"
                                  height="47.063"
                                  viewBox="0 0 140.705 47.063"
                                >
                                  <g
                                    id="SURVEY"
                                    transform="translate(-213.83 -599.91)"
                                  >
                                    <path
                                      id="button_2"
                                      d="M3096.706,5105.7l128.391-6.957-11.8,46.026h-108.18l-19.414-29.588Z"
                                      transform="translate(-2871.216 -4498.297)"
                                      stroke="#000"
                                      strokeWidth="1"
                                    />
                                    <text
                                      id="アンケートに関る"
                                      transform="translate(286 629)"
                                      fill="#fff"
                                      font-size="13"
                                      font-weight="400"
                                    >
                                      <tspan x="-35%" y="0" fontSize={14}>
                                        About the Survey
                                      </tspan>
                                    </text>
                                  </g>
                                </svg>
                              </button>
                            </Link>
                          )}
                        </>
                      )}
                    </>
                  </section>
                  <section className="logoContainer">
                    <img src={phan_logo} alt="logo" className="logo" />
                  </section>
                  <div className="PersonaMainPageLanguageWrapper">
                    <span
                      onClick={cnVersion}
                      className={`PersonaMainPageLanguageButton ${
                        pageLanguage === 1 ? "selected" : ""
                      }`}
                    >
                      中文
                    </span>{" "}
                    |{" "}
                    <span
                      onClick={enVersion}
                      className={`PersonaMainPageLanguageButton ${
                        pageLanguage === 2 ? "selected" : ""
                      }`}
                    >
                      EN
                    </span>{" "}
                    |{" "}
                    <span
                      onClick={jaVersion}
                      className={`PersonaMainPageLanguageButton ${
                        pageLanguage === 0 ? "selected" : ""
                      }`}
                    >
                      日本語
                    </span>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </article>
      )}
    </>
  );
}
