import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useRef, useEffect, useMemo, useState } from "react";
import "./AboutRightPage.scss";
import aboutWeb_svg from "../../../img/ProfilePage/EngSvgs/aboutWeb.svg";
import aboutWeb_svg_chn from "../../../img/ProfilePage/ChnSvgs/aboutWeb.svg";
import sharedNotes_svg from "../../../img/ProfilePage/EngSvgs/sharedNotes.svg";
import sharedNotes_svg_chn from "../../../img/ProfilePage/ChnSvgs/sharedNotes.svg";
import LookBack_png from "../../../img/ProfilePage/Imgs/lookback.png";
import PROFILE_DATA from "../../../data/profile/profile.json";

export default function AboutRightPage(props) {
  const pageLanguage = props.pageLanguage; // 1 - Chinese, 2 - English

  var containerTextStyles = {
    fontFamily: `${pageLanguage === 2 ? `Domine-Regular` : `FangZheng-KaiTi`}`,
    fontSize: `${pageLanguage === 2 ? 16 : 18}px`,
  };
  var mainTitleStyles = {
    fontFamily: `${pageLanguage === 2 ? `EnglishTowne` : `Sanji-Pomo`}`,
  };
  var subTitleStyles = {
    fontFamily: `${pageLanguage === 2 ? `Nirtiery-Quentin` : `BaoXiaoZhi`}`,
  };

  const data = useMemo(() => PROFILE_DATA);
  const item = data.find((item) => item.id === pageLanguage);
  const newsTitle = item ? item.newsTitle : "";
  const newsSubTitle = item ? item.newsSubTitle : "";
  const newsContent = item ? item.newsContent : "";
  const advertisingSpace = item ? item.advertisingSpace : "";
  const designTitle = item ? item.designTitle : "";
  const designContent = item ? item.designContent : "";
  const noteTitle = item ? item.noteTitle : "";
  const noteContent = item ? item.noteContent : "";
  const krenzNoteTitle = item ? item.krenzNoteTitle : "";
  const recruitingUsersTitle = item ? item.recruitingUsersTitle : "";
  const recruitingUsersContent = item ? item.recruitingUsersContent : "";

  return (
    <article className="AboutRightPageContainer" style={containerTextStyles}>
      <section className="AboutRightPageLeftContainer ">
        <section
          className="AboutRightPageLeftGoodNewsContainer"
          style={subTitleStyles}
        >
          {newsTitle}
        </section>
        <section className="AboutRightPageLeftGoodNewsSubTitle">
          {newsSubTitle}
        </section>
        <section className="AboutRightPageLeftGoodNewsContent">
          {newsContent}
        </section>
        <section className="AboutRightPageLeftGoodNewsImg"></section>
        <section className="AboutRightPageLeftContent" style={subTitleStyles}>
          {advertisingSpace}
        </section>
      </section>
      <section className="AboutRightPageRightContainer">
        <section className="AboutRightPageRightTopContainer">
          <div className="AboutRightPageRightTopImage"></div>
          <div className="AboutRightPageRightTopText">
            <div className="AboutRightPageRightTopTitle" style={subTitleStyles}>
              {designTitle}
            </div>
            <div
              className={`AboutRightPageRightTopContent  ${
                pageLanguage === 2
                  ? "AboutRightPageRightTopContentEnglish "
                  : ""
              }`}
            >
              {designContent}
            </div>
          </div>
        </section>
        <section
          className="AboutRightPageRightMiddleContainer"
          style={mainTitleStyles}
        >
          {recruitingUsersTitle}
        </section>
        <section className="AboutRightPageRightBottomContainer">
          <div className="AboutRightPageRightBottomImage"></div>
          <div className="AboutRightPageRightBottomText">
            {recruitingUsersContent}
          </div>
        </section>
      </section>
    </article>
  );
}
