import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";

import NarrowFooterBar from "./NarrowFooterBar/NarrowFooterBar";
import WideFooterBar from "./WideFooterBar/WideFooterBar";

import "./FooterBar.scss";

const FooterBar = (props) => {
  const updatePageLanguageVariable = props.updatePageLanguageVariable;
  const footerLanguage = props.footerLanguage;

  return (
    <article>
      <WideFooterBar
        updatePageLanguageVariable={updatePageLanguageVariable}
        footerLanguage={footerLanguage}
      />
    </article>
  );
};

export default FooterBar;
