const CODE_DATA = [
  { value: "array", label: "Array" },
  { value: "backtracking", label: "Backtracking" },
  { value: "binary_indexed_tree", label: "Binary Indexed Tree" },
  { value: "binary_search", label: "Binary Search" },
  { value: "binary_search_tree", label: "Binary Search Tree" },
  { value: "bit_manipulation", label: "Bit Manipulation" },
  { value: "bitmask", label: "Bitmask" },
  { value: "breadth_first_search", label: "Breadth-First Search" },
  { value: "bucket_sort", label: "Bucket Sort" },
  { value: "combinatorics", label: "Combinatorics" },
  { value: "concurrency", label: "Concurrency" },
  { value: "counting", label: "Counting" },
  { value: "counting_sort", label: "Counting Sort" },
  { value: "data_stream", label: "Data Stream" },
  { value: "database", label: "Database" },
  { value: "depth_first_search", label: "Depth-First Search" },
  { value: "design", label: "Design" },
  { value: "divide_and_conquer", label: "Divide and Conquer" },
  { value: "doubly_linked_list", label: "Doubly-Linked List" },
  { value: "dynamic_programming", label: "Dynamic Programming" },
  { value: "eulerian_circuit", label: "Eulerian Circuit" },
  { value: "game_theory", label: "Game Theory" },
  { value: "geometry", label: "Geometry" },
  { value: "graph", label: "Graph" },
  { value: "greedy", label: "Greedy" },
  { value: "hash_function", label: "Hash Function" },
  { value: "hash_table", label: "Hash Table" },
  { value: "heap", label: "Heap (Priority Queue)" },
  { value: "interactive", label: "Interactive" },
  { value: "iterator", label: "Iterator" },
  { value: "line_sweep", label: "Line Sweep" },
  { value: "linked_list", label: "Linked List" },
  { value: "math", label: "Math" },
  { value: "matrix", label: "Matrix" },
  { value: "memoization", label: "Memoization" },
  { value: "merge_sort", label: "Merge Sort" },
  { value: "minimum_spanning_tree", label: "Minimum Spanning Tree" },
  { value: "monotonic_queue", label: "Monotonic Queue" },
  { value: "monotonic_stack", label: "Monotonic Stack" },
  { value: "number_theory", label: "Number Theory" },
  { value: "ordered_set", label: "Ordered Set" },
  { value: "prefix_sum", label: "Prefix Sum" },
  { value: "probability_and_statistics", label: "Probability and Statistics" },
  { value: "queue", label: "Queue" },
  { value: "quickselect", label: "Quickselect" },
  { value: "radix_sort", label: "Radix Sort" },
  { value: "randomized", label: "Randomized" },
  { value: "recursion", label: "Recursion" },
  { value: "rejection_sampling", label: "Rejection Sampling" },
  { value: "reservoir_sampling", label: "Reservoir Sampling" },
  { value: "rolling_hash", label: "Rolling Hash" },
  { value: "shortest_path", label: "Shortest Path" },
  { value: "simulation", label: "Simulation" },
  { value: "sliding_window", label: "Sliding Window" },
  { value: "stack", label: "Stack" },
  { value: "string", label: "String" },
  { value: "string_matching", label: "String Matching" },
  { value: "suffix_array", label: "Suffix Array" },
  { value: "topological_sort", label: "Topological Sort" },
  { value: "tree", label: "Tree" },
  { value: "trie", label: "Trie" },
  { value: "two_pointers", label: "Two Pointers" },
  { value: "union_find", label: "Union Find" },
];

export default CODE_DATA;
