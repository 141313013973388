import React, { useState } from "react";
import "./InputBoxWithDropdown.scss";

const InputBoxWithDropdown = ({ onOptionSelect, defaultSelection }) => {
  const [selectedValue, setSelectedValue] = useState("");

  // Handle dropdown selection change
  const handleDropdownChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    onOptionSelect(value); // Invoke the callback function
  };

  return (
    <div>
      <select
        value={defaultSelection ? defaultSelection : selectedValue}
        onChange={handleDropdownChange}
        className="InputBoxWithDropdownContainer"
      >
        <option value="Others">Others</option>
        <option value="Leetcode Solution">Leetcode Solution</option>
        <option value="Development Errors">Development Errors</option>
      </select>
    </div>
  );
};

export default InputBoxWithDropdown;
