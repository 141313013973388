import React, { useEffect, useState, useMemo } from "react";
import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./SubNoteMenuPage.scss";
import { Amplify, Auth } from "aws-amplify";
import awsExports from "../../../aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faEarthAsia,
  faChevronRight,
  faBook,
  faGlasses,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import "@aws-amplify/ui-react/styles.css";

import HeaderBar from "../../../components/HeaderBar/HeaderBar";
import FooterBar from "../../../components/FooterBar/FooterBar";
import AuthCard from "../../../components/Cards/AuthCard/AuthCard";
import NOTE_DATA from "../../../data/notes/krenzNotes.json";
Amplify.configure(awsExports);

export default function SubNoteMenuPage() {
  /**
   * Check the language version from HeaderBar componentand update the content to that language
   * EN - English, CN - Chinese
   */
  const [pageLanguage, setPageLanguage] = useState("EN");
  const updatePageLanguageVariable = (languageValue) => {
    setPageLanguage(languageValue);
  };
  const navigate = useNavigate();
  // Retrieve the last visited URL from local storage
  const lastVisitedPage =
    localStorage.getItem("lastVisitedPage") == null
      ? sessionStorage.getItem("lastVisitedPage")
      : localStorage.getItem("lastVisitedPage");
  console.log("SubNoteMenuPage.js | lastVisitedPage = ", lastVisitedPage);

  /**
   * We need to get the class's url by current url's last three
   */
  const location = useLocation();
  const currentURL = location.pathname;
  const currentURLParts = currentURL.split("/");
  const noteData = useMemo(() => NOTE_DATA);
  const criteria = currentURLParts.slice(-2);
  const [mainShortenName, subShortenName] = criteria;
  const item = noteData.find(
    (data) =>
      data.mainShortenName === mainShortenName &&
      data.contents.find((content) => content.subShortenName === subShortenName)
  );
  const item2 = item.contents.find(
    (content) => content.subShortenName === subShortenName
  );

  const cardTitle = item2 ? item2.cardTitle : "";
  const cardTitle_EN = item2 ? item2.cardTitle_EN : "";
  const cardDescription = item2 ? item2.cardDescription : "";
  const cardDescription_EN = item2 ? item2.cardDescription_EN : "";
  const noteTaker = item2 ? item2.noteTaker : "";
  const updateTime = item2 ? item2.updateTime : "";
  const language = item2 ? item2.language : "";

  /** Get current user start, check auth*/
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        setUserLoggedIn(true);
      })
      .catch((err) => console.log(err));
  }, []);
  /** Get current user end*/

  const [showPopup, setShowPopup] = useState(false);
  const updateShowPopupVariable = (popupVariable) => {
    setShowPopup(popupVariable);
  };
  const handleClickCreatePreview = (previewLink) => {
    if (userLoggedIn) {
      navigate(previewLink);
    } else {
      setShowPopup(!showPopup);
    }
  };
  return (
    <article>
      <section>
        <HeaderBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          headerLanguage={pageLanguage}
        />
      </section>
      <section className="SubNoteMenuWelcome">
        <section className="SubNoteMenuWelcomeInfoSection">
          <span className="SubNoteMenuWelcomeInfoNav">
            <Link
              to={`/notes`}
              style={{ textDecoration: "none" }}
              className="SubNoteMenuPageImportant"
            >
              NOTES
            </Link>
            &nbsp;
            <FontAwesomeIcon icon={faChevronRight} />
            &nbsp;
            {pageLanguage === "EN" ? cardTitle_EN : cardTitle}
          </span>
          <span className="SubNoteMenuWelcomeInfoTitle">
            {pageLanguage === "EN" ? cardTitle_EN : cardTitle}
          </span>
          <span className="SubNoteMenuWelcomeInfoDetails">
            {pageLanguage === "EN" ? cardDescription_EN : cardDescription}
          </span>
          <span className="SubNoteMenuWelcomeInfoSubTitle">
            {pageLanguage === "EN" ? "By" : "来自"}{" "}
            <span className="SubNoteMenuPageImportant">{noteTaker}</span>
          </span>
          <span className="SubNoteMenuWelcomeInfoSubTitleRow ">
            <span>
              <FontAwesomeIcon icon={faCircleInfo} />
              &nbsp;&nbsp;{pageLanguage === "EN"
                ? "Last updated"
                : "最近更新"}{" "}
              {updateTime}
            </span>
            <span>
              <FontAwesomeIcon icon={faEarthAsia} />
              &nbsp;&nbsp;
              {pageLanguage === "EN" ? "Language options" : "支持语言"}{" "}
              {language}
            </span>
          </span>
        </section>
      </section>

      <section className="SubNoteMenuCourseContent">
        <span className="SubNoteMenuCourseContentTitle">
          {pageLanguage === "EN" ? "Course Content" : "笔记内容"}
        </span>
        <span className="SubNoteMenuCourseContentSubTitle">
          {item2.contents.length} {pageLanguage === "EN" ? "Notes" : "篇笔记"}
        </span>

        <section className="SubNoteMenuCourseContentMenuList">
          {item2.contents.map((subContent) => (
            <React.Fragment key={subContent.id}>
              <section className="SubNoteMenuCourseContentMenuRow">
                <section className="SubNoteMenuCourseContentMenuRowTitle">
                  <FontAwesomeIcon icon={faBook} />
                  <span>{subContent.title}</span>
                </section>
                <section className="SubNoteMenuCourseContentMenuRowTitle">
                  <div
                    onClick={() =>
                      handleClickCreatePreview(`${currentURL}/${subContent.id}`)
                    }
                    key={subContent.id}
                    style={{ textDecoration: "none" }}
                    className="SubNoteMenuPageView"
                  >
                    <FontAwesomeIcon icon={faGlasses} />
                    <span className="SubNoteMenuCourseContentMenuRowText ">
                      {" "}
                      &nbsp;&nbsp;{pageLanguage === "EN" ? "Preview" : "预览"}
                    </span>
                  </div>
                  {showPopup && (
                    <AuthCard
                      authCardTitle={
                        pageLanguage === "EN"
                          ? "Please Sign in to View Notes"
                          : "请登录后查看笔记"
                      }
                      updateShowPopupVariable={updateShowPopupVariable}
                      targetUrl={`${currentURL}/${subContent.id}`}
                    />
                  )}
                  <div className="SubNoteMenuPageDownload">
                    <FontAwesomeIcon icon={faDownload} />
                    <span className="SubNoteMenuCourseContentMenuRowText ">
                      &nbsp;&nbsp;{pageLanguage === "EN" ? "Download" : "下载"}
                    </span>
                  </div>
                </section>
              </section>
            </React.Fragment>
          ))}
        </section>
      </section>

      <section>
        <FooterBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          footerLanguage={pageLanguage}
        />
      </section>
    </article>
  );
}
