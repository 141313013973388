import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./PersonaLoadingPage.scss";
import loading_gif from "../../../img/PersonaPage/Loading/thieves.gif";

export default function PersonaLoadingPage() {
  return (
    <article className="PersonaLoadingPageContainer">
      <img src={loading_gif} alt="loading" className="gifContainer"></img>
    </article>
  );
}
