import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import "./NoteKrenzPage.scss";

import NOTE_DATA from "../../../data/notes/krenzNotes.json";

import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../../../aws-exports";

import popuku_png from "../../../img/NotePage/Popuku.png";

import HeaderBar from "../../../components/HeaderBar/HeaderBar";
import FooterBar from "../../../components/FooterBar/FooterBar";

const NoteKrenzPage = () => {
  /**
   * Check the language version from HeaderBar componentand update the content to that language
   * EN - English, CN - Chinese
   */
  const [pageLanguage, setPageLanguage] = useState("EN");
  const updatePageLanguageVariable = (languageValue) => {
    setPageLanguage(languageValue);
  };
  /** Two page View */
  const [twoPageView, setTwoPageView] = useState(
    window.innerWidth / window.innerHeight >= 1.5
  );
  useEffect(() => {
    const handleResize = () => {
      setTwoPageView(window.innerWidth / window.innerHeight >= 1.5);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  /**
   * We need to get the pdf's url by current url's last three
   */
  var pdfUrl;
  const location = useLocation();
  const currentURL = location.pathname;
  const currentURLParts = currentURL.split("/");
  const noteData = useMemo(() => NOTE_DATA);
  const criteria = currentURLParts.slice(-3);
  const [mainShortenName, subShortenName, id] = criteria;
  const item = noteData.find(
    (data) =>
      data.mainShortenName === mainShortenName &&
      data.contents.some(
        (content) =>
          content.subShortenName === subShortenName &&
          content.contents.some(
            (innerContent) => innerContent.id === Number(id)
          )
      )
  );
  if (item) {
    const { s3Url } = item.contents
      .find((content) => content.subShortenName === subShortenName)
      .contents.find((innerContent) => innerContent.id === Number(id));
    pdfUrl = s3Url;
  } else {
    console.log("Item not found.");
  }
  /**
   * Get pdf end
   */

  /**
   * Load PDF
   */
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const pageNumberList = Array.from(
    { length: numPages },
    (_, index) => index + 1
  );
  const goToPrevPage = () =>
    setPageNumber(pageNumber - 2 <= 1 ? 1 : pageNumber - 2);
  const goToNextPage = () =>
    setPageNumber(pageNumber + 2 > numPages ? pageNumber : pageNumber + 2);
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  });
  /**
   * Load PDF END
   */

  /**
   * Get current user
   */
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      // Optional, By default is false. If set to true,
      // this call will send a request to Cognito to get the latest user data
      bypassCache: false,
    })
      .then((user) => {
        setUserLoggedIn(true);
      })
      .catch((err) => console.log(err));
  }, []);
  /** User End */

  /** Sign In function */
  const navigate = useNavigate();
  async function signIn() {
    // Save the page in localStorage.
    localStorage.removeItem("lastVisitedPage");
    localStorage.setItem("lastVisitedPage", window.location.pathname);
    // Save the page in session storage.
    sessionStorage.removeItem("lastVisitedPage");
    sessionStorage.setItem("lastVisitedPage", window.location.pathname);
    navigate("/auth");
  }
  /** Sign End */

  const goToMenuPage = () => {
    const menuUrl = currentURLParts.slice(0, -1).join("/");
    navigate(menuUrl);
  };
  return (
    <div className="NoteKrenzPageContainer">
      <section className="NoteKrenzPageHeaderBarContainer">
        <HeaderBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          headerLanguage={pageLanguage}
          colorTheme={0}
        />
      </section>
      {userLoggedIn ? (
        <>
          {twoPageView ? (
            <>
              <section className="NoteKrenzPageSubHeaderContainer">
                <div className="NoteKrenzPageSubHeaderPageContainer">
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    size="lg"
                    onClick={goToPrevPage}
                  />
                  <div className="NoteKrenzPageNavPageContainer">
                    Page {pageNumber} of {numPages}
                  </div>

                  <FontAwesomeIcon
                    icon={faChevronRight}
                    size="lg"
                    onClick={goToNextPage}
                  />
                </div>
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  size="lg"
                  onClick={goToMenuPage}
                />
              </section>
              <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                className="NoteTwoPagePDFContainer"
              >
                <Page
                  pageNumber={pageNumber}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  className="NoteTwoPagePDFWrapper "
                />
                {pageNumber + 1 <= numPages ? (
                  <Page
                    pageNumber={pageNumber + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    className="NoteTwoPagePDFWrapper "
                  />
                ) : (
                  <div className="NoteTwoPagePDFWrapper "></div>
                )}
              </Document>
            </>
          ) : (
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              className="NoteOnePagePDFContainer"
            >
              {pageNumberList.map((pageNumberItem) => (
                <Page
                  pageNumber={pageNumberItem}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              ))}
            </Document>
          )}
        </>
      ) : (
        <section className="NoteKrenzPageSignInContainer">
          <div className="NoteKrenzPageSignInHeader"></div>
          <div className="NoteKrenzPageSignInBody ">
            <div className="NoteKrenzPageSignInImg">
              <img
                src={popuku_png}
                alt="popuku_png"
                className="PopukuPng"
              ></img>
            </div>
            <div className="NoteKrenzPageSignInText">
              呀嘞呀嘞
              <br /> 接下来需要注册后才能查看，
              <br /> 只需要邮箱注册不会收集个人信息，
              <br /> 放心吧！
            </div>

            <div onClick={signIn} className="NoteKrenzPageSignInButton">
              登录 / 注册
            </div>
          </div>
          <div className="NoteKrenzPageSignInHeader"></div>
        </section>
      )}
      <section className="NoteKrenzPageFooterBarContainer">
        <FooterBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          footerLanguage={pageLanguage}
        />
      </section>
    </div>
  );
};

export default NoteKrenzPage;
