import React, { useState, useRef, useEffect } from "react";
import "./FlexibleWindow.scss";

function FlexibleWindow(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const viewerRef = useRef(null);
  const dragOffset = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const viewer = viewerRef.current;
    if (isExpanded) {
      // 扩展状态
      viewer.style.width = "100%";
      viewer.style.height = "calc(100vh - 92px)"; // 减去 HeaderBar 和 FootBar 的高度
      viewer.style.top = "24px";
      viewer.style.left = "0";
    } else {
      // 缩小状态
      viewer.style.width = "75%";
      viewer.style.height = "calc(80vh - 92px)"; // 根据提供的样式调整高度
      viewer.style.top = "calc(24px + 12.5%)";
      viewer.style.left = "12.5%";
    }
  }, [isExpanded]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleMouseDown = (e) => {
    if (!isExpanded) {
      const viewer = viewerRef.current;
      dragOffset.current = {
        x: e.clientX - viewer.getBoundingClientRect().left,
        y: e.clientY - viewer.getBoundingClientRect().top,
      };
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleMouseMove = (e) => {
    const viewer = viewerRef.current;
    const viewerRect = viewer.getBoundingClientRect();
    let newLeft = e.clientX - dragOffset.current.x;
    let newTop = e.clientY - dragOffset.current.y;

    // 限制组件不要移动到视窗外
    newLeft = Math.max(newLeft, 0);
    newTop = Math.max(newTop, 0);
    newLeft = Math.min(newLeft, window.innerWidth - viewerRect.width);
    newTop = Math.min(newTop, window.innerHeight - viewerRect.height - 68);

    viewer.style.left = `${newLeft}px`;
    viewer.style.top = `${newTop}px`;
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <div
      ref={viewerRef}
      className={`pdf-viewer ${isExpanded ? "expanded" : "collapsed"}`}
    >
      <div className="left-bar">
        <button onClick={toggleExpand}>{isExpanded ? "缩小" : "扩展"}</button>
        <button onMouseDown={handleMouseDown}>移动</button>
      </div>
      {/* 菜单内容展示部分 */}
      <div className="content-display">
        {/* Display the number passed as prop */}
        {props.noteTitle}
      </div>
    </div>
  );
}

export default FlexibleWindow;
