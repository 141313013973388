import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../../aws-exports";

import Switch from "@mui/material/Switch";

import logo_white from "../../img/Logo/logo_white.svg";
import logo_black from "../../img/Logo/logo.svg";

import "./HeaderBar.scss";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const HeaderBar = (props) => {
  const headerLanguage = props.headerLanguage;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const openNoteDropDown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const updatePageLanguageVariable = props.updatePageLanguageVariable;
  const colorTheme = props.colorTheme;
  const [mobileView, setMobileView] = useState(window.innerWidth < 955);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 955) {
        setMobileView(false);
      } else {
        setMobileView(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  var menuStyles = {
    color: `white`,
  };
  var menuStyles_black = {
    color: `black`,
  };

  /** Get current user start*/
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userName, setUserName] = useState(null);
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        const nickname = user.attributes.nickname;
        setUserLoggedIn(true);
        setUserName(nickname);
      })
      .catch((err) => console.log(err));
  }, []);
  /** Get current user end*/
  /** Sign In & Sign Out function */
  const navigate = useNavigate();
  async function signIn() {
    // Save the page in localStorage.
    localStorage.removeItem("lastVisitedPage");
    localStorage.setItem("lastVisitedPage", window.location.pathname);
    // Save the page in session storage.
    sessionStorage.removeItem("lastVisitedPage");
    sessionStorage.setItem("lastVisitedPage", window.location.pathname);

    navigate("/auth");
  }
  async function signOut() {
    try {
      await Auth.signOut({ global: true });
      setUserLoggedIn(false);
      window.location.reload(true);
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  /** Sign End */
  /**
   * Switch Toggle For Language
   */
  const switchEN = () => {
    updatePageLanguageVariable("EN");
  };
  const switchCN = () => {
    updatePageLanguageVariable("CN");
  };
  const switchLanguage = (event) => {
    if (event.target.checked) {
      // Function to execute when the Switch is checked
      switchEN();
    } else {
      // Function to execute when the Switch is unchecked
      switchCN();
    }
  };
  return (
    <article
      className={`HeaderBarContainer  ${
        colorTheme === 0 ? "blackTheme" : "whiteTheme"
      } `}
    >
      <section className="HeaderBarMenuContainer">
        <Link
          to={`/notes`}
          style={colorTheme === 0 ? menuStyles_black : menuStyles}
          className="HeaderBarMenuItemText"
        >
          {headerLanguage === "EN" ? "NOTES" : "笔记"}
        </Link>
        <Link
          to={`/phan-site`}
          style={colorTheme === 0 ? menuStyles_black : menuStyles}
          className="HeaderBarMenuItemText"
        >
          {headerLanguage === "EN" ? "PHANSITE" : "偷心怪盗投票"}
        </Link>
        <Link
          to={`/discussion`}
          style={colorTheme === 0 ? menuStyles_black : menuStyles}
          className="HeaderBarMenuItemText"
        >
          {headerLanguage === "EN" ? "DISCUSSION" : "尽情吐槽区"}
        </Link>
      </section>
      <section className={`HeaderBarLogoContainer `}>
        <Link to={`/`}>
          <img
            src={colorTheme === 0 ? logo_black : logo_white}
            alt="logo_white"
            className="HeaderBarLogo"
          ></img>
        </Link>
      </section>
      <section className={`HeaderBarToolContainer `}>
        <section className="HeaderBarToolLanguageSwitch">
          <span
            style={colorTheme === 0 ? menuStyles_black : menuStyles}
            className="HeaderBarMenuItemText"
          >
            中文
          </span>
          <Switch
            defaultChecked
            onChange={switchLanguage}
            size="small"
            color="default"
          />
          <span
            style={colorTheme === 0 ? menuStyles_black : menuStyles}
            className="HeaderBarMenuItemText"
          >
            EN
          </span>
        </section>
        <section
          className={`HeaderBarToolAuthButton ${
            colorTheme === 0 ? "blackTheme" : "whiteTheme"
          }`}
          onClick={userLoggedIn ? signOut : signIn}
        >
          {userLoggedIn ? (
            <div className="HeaderBarToolAuthButtonText">
              {headerLanguage === "EN" ? "SIGN OUT" : "登出账户"}
            </div>
          ) : (
            <div className="HeaderBarToolAuthButtonText">
              {headerLanguage === "EN" ? "LOG IN" : "登录/注册"}
            </div>
          )}
        </section>
      </section>
    </article>
  );
};

export default HeaderBar;
