import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useRef, useEffect, useMemo, useState } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../../../aws-exports";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPenToSquare,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import HeaderBar from "../../../components/HeaderBar/HeaderBar";
import FooterBar from "../../../components/FooterBar/FooterBar";
import "./LeetcodeNoteContentPage.scss";

export default function LeetcodeNoteContentPage() {
  /**
   * Check the language version from HeaderBar componentand update the content to that language
   * EN - English, CN - Chinese
   */
  const [pageLanguage, setPageLanguage] = useState("EN");
  const updatePageLanguageVariable = (languageValue) => {
    setPageLanguage(languageValue);
  };
  /**
   * We need to get the question's number by current url's last one
   */
  const location = useLocation();
  const currentURL = location.pathname;
  const currentURLParts = currentURL.split("/");
  const navigate = useNavigate();
  const NOTES_API_INVOKE_URL =
    "https://jnhl0s4x28.execute-api.us-east-1.amazonaws.com/dev/notes";
  const [note, setNote] = useState(null);
  const noteId = currentURLParts.at(-1); // Replace with the actual ID you want to fetch

  useEffect(() => {
    getNoteById(noteId);
  }, []);

  /** Get current user start*/
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        setUserId(user.attributes.sub);
      })
      .catch((err) => console.log(err));
  }, []);
  /** Get current user end*/
  async function getNoteById(id) {
    try {
      const response = await axios.get(`${NOTES_API_INVOKE_URL}/${id}`);
      const { data } = response;
      setNote(data.Item); // Store the result in component state
    } catch (error) {
      console.log("Error fetching note:", error);
    }
  }

  async function handleDelete() {
    try {
      const response = await axios.delete(`${NOTES_API_INVOKE_URL}/${noteId}`);
      alert("Deleted");
      navigate("/notes");
    } catch (error) {
      console.log(error);
    }
  }

  async function handleEdit() {
    try {
      navigate("/leetcode-note/edit/" + noteId);
    } catch (error) {
      console.log(error);
    }
  }
  console.log("LeetcodeNoteContentPage | note.problemTag: ", note?.problemTag);
  return (
    <article className="LeetcodeNoteContentPageContainer">
      <section>
        <HeaderBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          headerLanguage={pageLanguage}
        />
      </section>
      {note ? (
        <>
          <section className="LeetcodeNoteContentPageBodyContainer">
            <section className="LeetcodeNoteContentPageBodyInfoContainer">
              <div>Published: {note.updateTime.substring(0, 10)}</div>
              <div>Author: {note.author}</div>
            </section>
            <div className="LeetcodeNoteContentPageBodyTitleContainer">
              <span>{note.title}</span>
            </div>
            <div className="LeetcodeNoteContentPageBodyExternalLinkContainer ">
              {pageLanguage === "EN" ? "Problem Link" : "问题链接"}:&nbsp;
              <Link to={note.problemLink}>{note.problemLink}</Link>
            </div>
            <section className="LeetcodeNoteContentPageBodySubTitleContainer">
              <section className="LeetcodeNoteContentPageBodySubTitles">
                {note.problemTag?.map((item) => (
                  <div
                    className="LeetcodeNoteContentPageBodySubTitleWrapper"
                    key={item.value}
                  >
                    {item.label}
                  </div>
                ))}
              </section>
              {userId === note.authorId ? (
                <section className="LeetcodeNoteContentPageBodySubButtons">
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{ color: "gray" }}
                    size="lg"
                    onClick={handleEdit}
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ color: "gray" }}
                    size="lg"
                    onClick={handleDelete}
                  />
                </section>
              ) : (
                <></>
              )}
            </section>
            <pre className="LeetcodeNoteContentPageBodyContentContainer">
              <ReactQuill
                value={note.description}
                readOnly={true}
                theme={"bubble"}
              />
            </pre>
          </section>
        </>
      ) : (
        <>Loading</>
      )}

      <section className="LeetcodeNoteContentPageFooterContainer">
        <FooterBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          footerLanguage={pageLanguage}
        />
      </section>
    </article>
  );
}
