import React, { useRef, useEffect, useMemo, useState } from "react";
import "./AboutLeftPage.scss";
import RadioChart from "../../../components/Charts/RadioChart";

import PROFILE_DATA from "../../../data/profile/profile.json";

import { getCurrentFormattedDate } from "../../../helper/dateUtil";

export default function AboutLeftPage(props) {
  const pageLanguage = props.pageLanguage; // 1 - Chinese, 2 - English
  var containerTextStyles = {
    fontFamily: `${pageLanguage === 2 ? `Domine-Regular` : `FangZheng-KaiTi`}`,
    fontSize: `${pageLanguage === 2 ? 16 : 18}px`,
  };
  var mainTitleStyles = {
    fontFamily: `${pageLanguage === 2 ? `EnglishTowne` : `Sanji-Pomo`}`,
  };
  var subTitleStyles = {
    fontFamily: `${pageLanguage === 2 ? `Nirtiery-Quentin` : `BaoXiaoZhi`}`,
  };

  /**
   * Preview Resume
   */
  const openPDF = () => {
    const pdfUrl =
      "https://chensitesbucket.s3.amazonaws.com/chenchen/Yichen_Resume.pdf";
    window.open(pdfUrl, "_blank");
  };
  /**
   * Change page language
   */

  const data = useMemo(() => PROFILE_DATA);
  const item = data.find((item) => item.id === pageLanguage);
  const mainTitle = item ? item.mainTitle : "";
  const subTitle = item ? item.subTitle : "";
  const personalData = item ? item.personalData : "";
  const basicDataContent = item ? item.basicDataContent : "";
  const wangsNews = item ? item.wangsNews : "";
  const nameTitle = item ? item.nameTitle : "";
  const nameValue = item ? item.nameValue : "";
  const graduateYearTitle = item ? item.graduateYearTitle : "";
  const graduateYearValue = item ? item.graduateYearValue : "";
  const degreeTitle = item ? item.degreeTitle : "";
  const degreeValue = item ? item.degreeValue : "";
  const workingExperienceTitle = item ? item.workingExperienceTitle : "";
  const workingExperienceValue = item ? item.workingExperienceValue : "";
  const professionalResumeTitle = item ? item.professionalResumeTitle : "";
  const previewText = item ? item.previewText : "";

  const skillsTitle = item ? item.skillsTitle : "";
  const skillsContent = item ? item.skillsContent : "";

  /** Get today's date */
  const formattedDate = getCurrentFormattedDate();
  return (
    <article className="AboutLeftPageContainer" style={containerTextStyles}>
      <section
        className="AboutLeftPageMainTitleContainer"
        style={mainTitleStyles}
      >
        {mainTitle}
      </section>
      <section className="AboutLeftPageDateContainer">
        <div>EXP 1995</div>
        <div>{formattedDate}</div>
        <div>EXP 1995</div>
      </section>
      <section className="AboutLeftPageDataNewsContainer">
        <div className="AboutLeftPageDataContainer">
          <section className="AboutLeftPageDataImgContainer"></section>
          <section
            className="AboutLeftPageDataTitleContainer"
            style={subTitleStyles}
          >
            {personalData}
          </section>
          <section className="AboutLeftPageDataText">
            <div className="AboutLeftPageDataContents">{basicDataContent}</div>
            <div className="AboutLeftPageDataList">
              <div className="AboutLeftPageDataListItems">
                <div className="AboutLeftPageDataRow">
                  <span>{nameTitle}</span>
                  <span>{nameValue}</span>
                </div>
                <div className="AboutLeftPageDataRow">
                  <span>{graduateYearTitle}</span>
                  <span>{graduateYearValue}</span>
                </div>
                <div className="AboutLeftPageDataRow">
                  <span>{degreeTitle}</span>
                  <span>{degreeValue}</span>
                </div>
                <div className="AboutLeftPageDataRow">
                  <span>{workingExperienceTitle}</span>
                  <span>{workingExperienceValue}</span>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="AboutLeftPageNewsContainer">
          <div className="AboutLeftPageNewsTitle" style={subTitleStyles}>
            {subTitle}
          </div>
          <section className="AboutLeftPageNewsImage"></section>
          <section
            className={`AboutLeftPageNewsText  ${
              pageLanguage === 2 ? "" : "AboutLeftPageNewsChineseText"
            }`}
          >
            {wangsNews}
          </section>
        </div>
      </section>
      <section className="AboutLeftPageSkillsContainer">
        <div className="AboutLeftPageSkillsChartContainer">
          <div
            className="AboutLeftPageSkillsChartContainer"
            style={{ padding: "10px" }}
          >
            <RadioChart />
          </div>
        </div>
        <div className="AboutLeftPageSkillsTextContainer">
          <div
            className="AboutLeftPageSkillsTitleContainer"
            style={subTitleStyles}
          >
            {skillsTitle}
          </div>
          <div className="AboutLeftPageSkillsContentContainer">
            {skillsContent}
          </div>
          <div className="AboutLeftPageSkillsImgContainer"></div>
        </div>
      </section>
    </article>
  );
}
