import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import BootstrapTable from "react-bootstrap/Table";
import { useTable, useSortBy, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

import "./LeetcodeNotesTable.scss";

const LeetcodeNotesTable = ({ notes }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "problemNumber",
      },
      {
        Header: "Title",
        accessor: "title",
        disableSortBy: true,
        Cell: ({ row }) => (
          <div className="title-cell">
            <Link
              to={`/leetcode-note/${row.original.id}`}
              style={{ textDecoration: "none", color: "black" }}
            >
              {row.original.title.length > 30 && !showSubmitTimeColumn
                ? `${row.original.title.substring(0, 30)}...`
                : row.original.title}
            </Link>
          </div>
        ),
      },
      {
        Header: "Submit Time",
        accessor: "date",
        Cell: ({ row }) => (
          <span>
            {new Date(row.original.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </span>
        ),
      },
    ],
    []
  );

  // Use window width to determine whether to show "Submit time" column
  const [showSubmitTimeColumn, setShowSubmitTimeColumn] = useState(
    window.innerWidth >= 955
  );

  useEffect(() => {
    const handleResize = () => {
      setShowSubmitTimeColumn(window.innerWidth >= 955);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Filter columns based on window width
  const filteredColumns = useMemo(() => {
    return showSubmitTimeColumn
      ? columns
      : columns.filter((column) => column.Header !== "Submit Time");
  }, [columns, showSubmitTimeColumn]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage, // Import gotoPage
    canPreviousPage, // Import canPreviousPage
    previousPage, // Import previousPage
    canNextPage, // Import canNextPage
    nextPage, // Import nextPage
    pageCount, // Import pageCount
    pageOptions, // Import pageOptions
    setPageSize, // Import setPageSize
  } = useTable(
    {
      columns: filteredColumns, // Use the filtered columns
      data: notes,
      initialState: {
        sortBy: [{ id: "problemNumber", desc: false }], // Sort by "#" column by default
        pageIndex: 0, // Start from the first page
        pageSize: 5, // Display 6 items per page
      },
    },
    useSortBy,
    usePagination
  );

  // CSS styles for column widths
  const columnStyles = useMemo(
    () => ({
      "#": { width: "12%" },
      Title: { width: "70%" },
    }),
    []
  );

  return (
    <div className="LeetcodeNotesTableContainer">
      <table className="custom-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={columnStyles[column.Header]}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.column.Header === "Title" ? (
                        <div className="title-cell">{cell.render("Cell")}</div>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="LeetcodeNotesTablePaginationContainer">
        <section className="LeetcodeNotesTablePaginationContainer_Buttons">
          <span>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>
          </span>

          <span>
            Page &nbsp;
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <span>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
          </span>
        </section>

        <section className="LeetcodeNotesTablePaginationContainer_Inputs">
          <span>
            Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "50px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </section>
      </div>
    </div>
  );
};

export default LeetcodeNotesTable;
