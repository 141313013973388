import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";

import "./NoteCard.scss";

const NoteCard = (props) => {
  const languageVersion = props.languageVersion;
  const coverImageUrl = props.coverImageUrl;
  const noteTitle = props.noteTitle;
  const noteDescription = props.noteDescription;
  const noteTaker = props.noteTaker;
  const noteCount = props.noteCount;
  const linkUrl = props.linkUrl;

  return (
    <article className="NoteCardContainer">
      <section className="NoteCardImage">
        <img
          src={coverImageUrl}
          alt="Cover Image"
          className="NoteCardImage"
        ></img>
      </section>
      <section className="NoteCardInfoContainer">
        <Link to={linkUrl} style={{ textDecoration: "none" }}>
          <header className="NoteCardTitle">{noteTitle}</header>
        </Link>
        <span className="NoteCardInfo">{noteDescription}</span>
        <span className="NoteCardNoteTaker">
          {languageVersion === "EN" ? "By" : "来自"} {noteTaker}
        </span>
        <span className="NoteCardNoteTaker">
          {noteCount}&nbsp;&nbsp;
          {languageVersion === "EN" ? "notes" : "篇笔记"}
        </span>
        <div className="NoteCardBadge">Most Viewed</div>
      </section>
    </article>
  );
};

export default NoteCard;
