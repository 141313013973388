import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../../../aws-exports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare } from "@fortawesome/free-solid-svg-icons";
import HeaderBar from "../../../components/HeaderBar/HeaderBar";
import FooterBar from "../../../components/FooterBar/FooterBar";
import LeetcodeNotesTable from "../../../components/Tables/LeetcodeNotesTable/LeetcodeNotesTable";
import SwipeCard from "../../../components/Cards/SwipeCard/SwipeCard";
import NoteCard from "../../../components/Cards/NoteCard/NoteCard";
import MapCard from "../../../components/Cards/MapCard/MapCard";
import TravelNoteCards from "../../../components/Cards/TravelNoteCards/TravelNoteCards";

import havefun_svg from "../../../img/Slogan/haveFunAndLearn.svg";
import "./NoteMainPage.scss";

import NOTE_DATA from "../../../data/notes/krenzNotes.json";

import { Swiper, SwiperSlide } from "swiper/react";
import { register } from "swiper/element/bundle";
register();

const NoteMainPage = () => {
  /**
   * Check the language version from HeaderBar componentand update the content to that language
   * EN - English, CN - Chinese
   */
  const [pageLanguage, setPageLanguage] = useState("EN");
  const updatePageLanguageVariable = (languageValue) => {
    setPageLanguage(languageValue);
  };

  const [mobileView, setMobileView] = useState(window.innerWidth < 955);

  var krenzMenuStyles = {
    fontFamily: `${
      pageLanguage === "EN" ? `NanumPenScript-Regular` : `BaoXiaoZhi`
    }`,
  };

  const data = useMemo(() => NOTE_DATA);
  const item = data.find((item) => item.id === 1);
  const mainShortenName = item ? item.mainShortenName : "";
  const contents = item ? item.contents : null;

  /**
   * expand/collapse function
   */
  // State variable to track the currently expanded subContent
  const [expandedSubContent, setExpandedSubContent] = useState(null);

  // Function to handle the expand/collapse action
  const handleExpandCollapse = (subContentId) => {
    if (expandedSubContent === subContentId) {
      setExpandedSubContent(null);
    } else {
      setExpandedSubContent(subContentId);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 955) {
        setMobileView(false);
      } else {
        setMobileView(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /** Get current user start, check auth*/
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        setUserIsAdmin(user.attributes.email == "yichenwaa@gmail.com");
      })
      .catch((err) => console.log(err));
  }, []);
  /** Get current user end*/

  const navigate = useNavigate();
  const handleClickCreateLeetcodeNote = () => {
    navigate("/leetcode-note/publish");
  };

  const NOTES_API_INVOKE_URL =
    "https://jnhl0s4x28.execute-api.us-east-1.amazonaws.com/dev/notes";
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    getAllNotes();
  }, []);

  /** Get All Note */
  async function getAllNotes() {
    try {
      const response = await fetch(NOTES_API_INVOKE_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
      });
      if (response.ok) {
        const data = await response.text();
        const result = JSON.parse(data);
        setNotes(result.Items);
        setLoading(false); // Set loading to false when data is fetched
      } else {
        console.log(
          "LeetcodeNotesTable.js | getAllNotes() request failed with status: ",
          response.status
        );
        setLoading(false); // Set loading to false even in case of an error
      }
    } catch (error) {
      console.log("LeetcodeNotesTable.js | getAllNotes() error: ", error);
      setLoading(false); // Set loading to false in case of an error
    }
  }
  /** GET End */
  console.log("NoteMainPage.js | notes: ", notes);

  return (
    <article className="NoteMainPage">
      <section className="NoteMainPageHeader">
        <HeaderBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          headerLanguage={pageLanguage}
        />
      </section>

      <section className="NoteMainPageWelcome"></section>
      <section className="NoteMainPageMainBody">
        <section className="NoteMainPageTitle">
          {pageLanguage === "EN" ? "Most Popular" : "大家都在看"}
        </section>
      </section>
      <section className="NoteMainPageMainBodySwiper">
        <swiper-container
          navigation="true"
          pagination="true"
          class="swiperContainer"
        >
          {contents.map((subContent) => (
            <React.Fragment key={subContent.id}>
              <swiper-slide class="blue-slide">
                <SwipeCard
                  languageVersion={pageLanguage === "EN" ? "EN" : "CN"}
                  noteTitle={
                    pageLanguage === "EN"
                      ? subContent.cardTitle_EN
                      : subContent.cardTitle
                  }
                  noteDescription={
                    pageLanguage === "EN"
                      ? subContent.cardDescription_EN
                      : subContent.cardDescription
                  }
                  noteTaker={subContent.noteTaker}
                  updateTime={subContent.updateTime}
                  supportLanguage={subContent.language}
                  coverImageUrl={subContent.coverImageUrl}
                  linkUrl={`/notes/${mainShortenName}/${subContent.subShortenName}`}
                />
              </swiper-slide>
            </React.Fragment>
          ))}
        </swiper-container>
      </section>
      <section className="NoteMainPageMainBody">
        <section className="NoteMainPageSubTitle">
          {pageLanguage === "EN" ? "All Notes" : "所有笔记"}
        </section>

        <section className="NoteMainPageNoteCardContainer">
          {contents.map((subContent) => (
            <React.Fragment key={subContent.id}>
              <NoteCard
                languageVersion={pageLanguage === "EN" ? "EN" : "CN"}
                noteTitle={
                  pageLanguage === "EN"
                    ? subContent.cardTitle_EN
                    : subContent.cardTitle
                }
                noteDescription={
                  pageLanguage === "EN"
                    ? subContent.cardDescription_EN
                    : subContent.cardDescription
                }
                noteTaker={subContent.noteTaker}
                supportLanguage={subContent.language}
                coverImageUrl={subContent.coverImageUrl}
                linkUrl={`/notes/${mainShortenName}/${subContent.subShortenName}`}
                noteCount={subContent.contents.length}
              />
            </React.Fragment>
          ))}
        </section>

        <section className="NoteMainPageSubTitle">
          {pageLanguage === "EN" ? "My Travel Notes" : "我的旅行笔记"}
        </section>
        <section className="NoteMainPageNoteCardContainer">
          <MapCard />
        </section>
        <section className="NoteMainPageNoteCardContainer">
          <TravelNoteCards pageLanguage={pageLanguage} />
        </section>

        <section id="leetcodeNotes">
          <section className="NoteMainPageLeetcodeNotes">
            <section className="NoteMainPageSubTitle">
              {pageLanguage === "EN"
                ? "Leetcode Solution Notes"
                : "力扣解题笔记"}
            </section>
            {userIsAdmin ? (
              <FontAwesomeIcon
                icon={faPencilSquare}
                size="lg"
                color="black"
                onClick={handleClickCreateLeetcodeNote}
              />
            ) : (
              <></>
            )}
          </section>
          <LeetcodeNotesTable notes={notes} />
        </section>
      </section>

      <section className="NoteMainFooterContainer">
        <FooterBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          footerLanguage={pageLanguage}
        />
      </section>
    </article>
  );
};

export default NoteMainPage;
