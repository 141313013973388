import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";

import "./SwipeCard.scss";

const SwipeCard = (props) => {
  const languageVersion = props.languageVersion;
  const coverImageUrl = props.coverImageUrl;
  const noteTitle = props.noteTitle;
  const noteDescription = props.noteDescription;
  const noteTaker = props.noteTaker;
  const updateTime = props.updateTime;
  const supportLanguage = props.supportLanguage;
  const linkUrl = props.linkUrl;
  return (
    <article className="SwipeCardContainer">
      <section className="SwipeCardImageContainer">
        <img
          src={coverImageUrl}
          alt="Cover Image"
          className="SwipeCardImage"
        ></img>
      </section>
      <section className="SwipeCardContentContainer">
        <Link to={linkUrl} style={{ textDecoration: "none" }}>
          <header className="SwipeCardTitle">{noteTitle}</header>
        </Link>

        <span className="SwipeCardContent SwipeCardInfo">
          {noteDescription}
        </span>

        <span className="SwipeCardContent">
          {languageVersion === "EN" ? "By" : "来自"} {noteTaker}
        </span>

        <span className="SwipeCardContent">
          <span className="SwipeCardLanguage">
            {languageVersion === "EN" ? "Language options" : "支持语言"}
          </span>
          &nbsp;
          <span className="SwipeCardLanguageText">{supportLanguage}</span>
        </span>

        <span className="SwipeCardContent SwipeCardUpdated">
          {languageVersion === "EN" ? "Updated" : "更新于"}{" "}
          <span className="SwipeCardDate">{updateTime}</span>
        </span>
      </section>
    </article>
  );
};

export default SwipeCard;
