// src/pages/App/PrivacyPolicyPage/PrivacyPolicyPage.js
import React, { useState } from "react";
import "./PrivacyPolicyPage.scss";
const PrivacyPolicyPage = () => {
  const [language, setLanguage] = useState("English");

  const handleLanguageSwitch = (lang) => {
    setLanguage(lang);
  };
  return (
    <div>
      <div className="privacyPolicyPage">
        {language === "English" ? (
          <div>
            <div className="FirstRow">
              <h1>APP Registration and Use Privacy Agreement</h1>
              <div>
                [{" "}
                <span onClick={() => handleLanguageSwitch("Chinese")}>
                  中文
                </span>{" "}
                |{" "}
                <span onClick={() => handleLanguageSwitch("English")}>
                  English
                </span>{" "}
                ]
              </div>
            </div>
            <p>
              We specifically remind you (the user) to carefully read the "User
              Agreement" (hereinafter referred to as "the Agreement") before
              registering as a user, to ensure that you fully understand the
              terms of this Agreement. Please read carefully and choose to
              accept or reject this Agreement. Unless you accept all the terms
              of this Agreement, you do not have the right to register, log in,
              or use the services covered by this Agreement. Your registration,
              login, and use of the services will be regarded as acceptance of
              this Agreement and agreement to adhere to its terms. This
              Agreement stipulates the rights and obligations between EaseStar
              Development Center and users regarding the "EaseStar" software
              services (hereinafter referred to as "Service"). "User" refers to
              the individual who registers, logs in, and uses this service. This
              Agreement may be updated by EaseStar Development Center at any
              time without further notice, and the updated Agreement terms will
              replace the original terms once published. Users can review the
              latest version of the Agreement in this APP. If a user does not
              accept the modified terms, please immediately stop using the
              services provided by EaseStar Development Center within the
              software application. Continuing to use the services provided by
              EaseStar Development Center will be regarded as acceptance of the
              modified Agreement.
            </p>
            <h2>1. Account Registration</h2>
            <p>
              Users need to register a personal account when using the EaseStar
              application services. The EaseStar Development Center account must
              be registered using a mobile phone number. Please use a mobile
              phone number that has not been previously bound to any "EaseStar
              and related software" account and is not banned by the EaseStar
              Development Center according to this Agreement. EaseStar
              Development Center may change the method of account registration
              and binding according to user needs or product requirements
              without prior notice.
            </p>
            <h2>2. User Personal Privacy Protection</h2>
            <p>
              If EaseStar Development Center discovers or receives reports or
              complaints that a user has violated the terms of this Agreement,
              EaseStar Development Center has the right to review and delete
              relevant content, including but not limited to user data and
              community post records, without notice, and impose penalties on
              violating accounts including but not limited to warnings, account
              bans, and function bans, notifying the user of the outcome.
            </p>
            <h2>3. User Content Standards</h2>
            <p>
              This clause refers to any content replicated, published, or
              disseminated by users during the use of the EaseStar application,
              including but not limited to account avatars, names, user
              descriptions, registration information, and authentication data,
              or text, voice, pictures, videos, graphics and text messages,
              replies or automated replies, and related link pages, and other
              content generated using the account or this service.
            </p>
            <h2>4. Usage Rules</h2>
            <p>
              Any content transmitted or published by users in this service or
              through this service does not reflect, nor should it be regarded
              as reflecting, the views, position, or policy of the EaseStar
              Development Center. EaseStar Development Center does not assume
              any responsibility for this.
            </p>
            <h2>5. Age Restrictions</h2>
            <p>
              Our application is not open to minors. You must be at least 17
              years old to use this application. By clicking the "Agree" button
              during the registration process, you declare and guarantee that
              you are at least 17 years old and fully understand and agree to
              the terms of this Privacy Policy. Since we cannot determine
              whether the content of the application is suitable for all age
              groups, we recommend that parents supervise their children's
              Internet usage.
            </p>
            <h2>6. Other</h2>
            <p>
              EaseStar Development Center solemnly reminds users to pay
              attention to the clauses in this Agreement that exonerate EaseStar
              Development Center's liability and restrict users' rights. Please
              read carefully and consider the risks independently. Minors should
              read this Agreement in the presence of a legal guardian.
            </p>
          </div>
        ) : (
          // Insert the Chinese version of the policy here
          <div>
            <div className="FirstRow">
              <h1>用户注册及使用APP隐私协议</h1>
              <div>
                [{" "}
                <span onClick={() => handleLanguageSwitch("Chinese")}>
                  中文
                </span>{" "}
                |{" "}
                <span onClick={() => handleLanguageSwitch("English")}>
                  English
                </span>{" "}
                ]
              </div>
            </div>

            <p>
              在此特别提醒您（用户）在注册成为用户之前，请认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。本协议约定辰宝（EaseStar）开发中心与用户之间关于“辰宝（EaseStar）”软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。本协议可由辰宝（EaseStar）开发中心随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本APP中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用辰宝（EaseStar）开发中心提供的软件应用内的服务，用户继续使用辰宝（EaseStar）开发中心提供的服务将被视为接受修改后的协议。
            </p>
            <h2>一、账号注册</h2>
            <p>
              用户在使用辰宝（EaseStar）应用服务时需要注册一个个人账号。辰宝（EaseStar）开发中心账号应当使用手机号码绑定注册，请用户使用尚未与“辰宝（EaseStar）及相关软件”账号绑定的手机号码，以及未被辰宝（EaseStar）开发中心根据本协议封禁的手机号码注册辰宝（EaseStar）个人账号。辰宝（EaseStar）开发中心可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。
            </p>
            <h2>二、用户个人隐私信息保护</h2>
            <p>
              如果辰宝（EaseStar）开发中心发现或收到他人举报或投诉用户违反本协议约定的，辰宝（EaseStar）开发中心有权不经通知随时对相关内容，包括但不限于用户资料、社区发帖记录进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁、功能封禁的处罚，且通知用户处理结果。
            </p>
            <h2>三、用户发布内容规范</h2>
            <p>
              本条所述内容是指用户使用辰宝（EaseStar）应用的过程中所复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用账号或本服务所产生的内容。
            </p>
            <h2>四、使用规则</h2>
            <p>
              用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表辰宝（EaseStar）开发中心的观点、立场或政策，辰宝（EaseStar）开发中心对此不承担任何责任。
            </p>
            <h2>五、年龄限制</h2>
            <p>
              我们的应用不向未成年人开放。您必须至少年满17岁才能使用本应用。在注册过程中，点击“同意”按钮即表示您声明并保证您至少17岁，并且能够完全理解并同意本隐私政策的条款。由于我们无法确定应用内容是否适合所有年龄段，我们建议父母监督未成年人的互联网使用。
            </p>
            <h2>六、其他</h2>
            <p>
              辰宝（EaseStar）开发中心郑重提醒用户注意本协议中免除辰宝（EaseStar）开发中心责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
