import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useId, useRef, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { v4 } from "uuid";

import ReactQuill from "react-quill";
import Select from "react-select";

import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../../aws-exports";

import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import HeaderBar from "../../components/HeaderBar/HeaderBar";
import FooterBar from "../../components/FooterBar/FooterBar";

import InputBoxWithDropdown from "../../components/InputBoxWithDropdown/InputBoxWithDropdown";

import "./EditPage.scss";

import CODE_DATA from "../../data/notes/leetcodeNotes";

export default function EditPage() {
  /**
   * Check the language version from HeaderBar componentand update the content to that language
   * EN - English, CN - Chinese
   */
  const [pageLanguage, setPageLanguage] = useState("EN");
  const updatePageLanguageVariable = (languageValue) => {
    setPageLanguage(languageValue);
  };
  /** Get current user start*/
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        setUserLoggedIn(true);
        setUserName(user.attributes.nickname);
        setUserId(user.attributes.sub);
      })
      .catch((err) => console.log(err));
  }, []);
  /** Get current user end*/
  const navigate = useNavigate();
  const NOTES_API_INVOKE_URL =
    "https://jnhl0s4x28.execute-api.us-east-1.amazonaws.com/dev/notes";

  /**
   * We need to get the current note's id by current url's last one
   */
  const location = useLocation();
  const currentURL = location.pathname;
  const currentURLParts = currentURL.split("/");

  const [note, setNote] = useState(null);
  const noteId = currentURLParts.at(-1); // Replace with the actual ID you want to fetch

  useEffect(() => {
    getNoteById(noteId);
  }, []);

  async function getNoteById(id) {
    try {
      const response = await axios.get(`${NOTES_API_INVOKE_URL}/${id}`);
      const { data } = response;
      setNote(data.Item); // Store the result in component state
    } catch (error) {
      console.log("Error fetching note:", error);
    }
  }
  const modules = {
    toolbar: [
      // options here
      ["bold", "strike"], // toggled buttons
      ["code-block"],
      [{ list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      ["clean"],
    ],
  };

  const [problemNumber, setProblemNumber] = useState("");
  const [problemLink, setProblemLink] = useState("");
  const [problemTitle, setProblemTitle] = useState("");
  const [problemTag, setProblemTag] = useState([]);
  const [submitTime, setSubmitTime] = useState(null);
  const [description, setDescription] = useState("");
  const [postType, setPostType] = useState("Others");
  useEffect(() => {
    setProblemTitle(note ? note.title : "");
    setSubmitTime(
      note ? (note.date ? dayjs(note.date.substring(0, 10)) : null) : null
    );
    setProblemNumber(
      note ? (note.problemNumber ? note.problemNumber : "") : ""
    );
    setProblemLink(note ? (note.problemLink ? note.problemLink : "") : "");
    setDescription(note ? note.description : "");
    setPostType(note ? note.noteType : "Others");
    setProblemTag(note ? (note.problemTag ? note.problemTag : []) : []);
  }, [note]);
  const handleProblemNumberChange = (event) => {
    setProblemNumber(event.target.value.toString());
  };
  const handleProblemLinkChange = (event) => {
    setProblemLink(event.target.value.toString());
  };
  const handleTitleChange = (event) => {
    setProblemTitle(event.target.value.toString());
  };
  const handleTagChange = (selectedOptions) => {
    setProblemTag(selectedOptions);
    console.log("Selected options:", selectedOptions);
    // 进行其他处理
  };
  const handleSubmitTimeChange = (date) => {
    setSubmitTime(date);
  };
  const handleDescriptionChange = (value) => {
    setDescription(value); // Update the state with the editor content
  };
  const handleSave = () => {
    publishNote();
    alert("Published");
    navigate("/notes"); // CV0-43 change to navigate to note page
  };

  /**
   *
   * @param {*} selectedOption
   */

  const handleOptionSelect = (selectedOption) => {
    console.log("Selected option:", selectedOption);
    setPostType(selectedOption);
    // Perform any additional actions with the selected option
  };

  /** Publish a Note */
  async function publishNote() {
    // console.log("EditPage.js | publishNote() running ......");
    const now = new Date().toISOString();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      id: noteId,
      noteType: postType,
      title: problemTitle,
      problemNumber: problemNumber ? problemNumber : "",
      problemLink: problemLink ? problemLink : "",
      problemTag: problemTag ? problemTag : [],
      date: submitTime ? submitTime : now,
      updateTime: now,
      description: description,
      author: userName,
      authorId: userId,
    });
    try {
      const response = await fetch(NOTES_API_INVOKE_URL, {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      });
      const result = JSON.parse(await response.text()).body;
      console.log("EditPage.js | publishNote() result: ", result);
    } catch (error) {
      console.log("error", error);
    }
  }
  /** Publish End */
  return (
    <article className="EditPageContainer">
      <section>
        <HeaderBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          headerLanguage={pageLanguage}
        />
      </section>
      {userLoggedIn ? (
        <section className="EditPageBodyContainer">
          <section className="EditPageLogoContainer" id="Logo">
            {pageLanguage === "EN" ? "Edit the Post" : "编辑文章"}
          </section>

          <section className="EditPageTypeRow">
            <div className="EditPageColumn">
              <section className="EditPageTitleContainer">
                <div className="EditPageInputTitleContainer">
                  {pageLanguage === "EN" ? "Post Area *" : "发布板块 *"}
                </div>
                <InputBoxWithDropdown
                  className="EditPageInputContainer"
                  onOptionSelect={handleOptionSelect}
                  defaultSelection={postType}
                />
              </section>
            </div>
          </section>
          <section className="EditPageTitleContainer" id="Title">
            <div className="EditPageInputTitleContainer">
              {pageLanguage === "EN" ? "Post Title *" : "文章标题 *"}
            </div>
            <input
              name="title"
              type="string"
              onChange={handleTitleChange}
              className="EditPageInputContainer"
              value={
                problemTitle !== "" ? problemTitle : note ? note.title : ""
              }
            />
          </section>
          <section>
            <div className="EditPageInputTitleContainer">
              {pageLanguage === "EN" ? "Tags" : "标签"}
            </div>
            <Select
              isMulti
              name="colors"
              options={CODE_DATA}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleTagChange}
              value={
                problemTag !== [] ? problemTag : note ? note.problemTag : []
              }
            />
          </section>
          <section className="EditPageTypeRow">
            {postType === "Leetcode Solution" ? (
              <>
                <div className="EditPageColumn">
                  <section className="EditPageTitleContainer">
                    <div className="EditPageInputTitleContainer">
                      {pageLanguage === "EN"
                        ? "Problem Number"
                        : "Leetcode问题序号"}
                    </div>
                    <input
                      name="number"
                      type="string"
                      onChange={handleProblemNumberChange}
                      className="EditPageInputContainer"
                      value={
                        problemNumber !== ""
                          ? problemNumber
                          : note
                          ? note.problemNumber
                          : ""
                      }
                    />
                  </section>
                </div>
                <div className="EditPageColumn">
                  <section className="EditPageTitleContainer">
                    <div className="EditPageInputTitleContainer">
                      {pageLanguage === "EN"
                        ? "Problem Link on Leetcode"
                        : "Leetcode问题链接"}
                    </div>
                    <input
                      name="number"
                      type="string"
                      onChange={handleProblemLinkChange}
                      className="EditPageInputContainer"
                      value={
                        problemLink !== ""
                          ? problemLink
                          : note
                          ? note.problemLink
                          : ""
                      }
                    />
                  </section>
                </div>
                <div className="EditPageColumn">
                  <section className="EditPageTitleContainer">
                    <div className="EditPageInputTitleContainer">
                      {pageLanguage === "EN" ? "Submit Time" : "提交时间"}
                    </div>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      dateLibInstance={dayjs}
                    >
                      <DatePicker
                        name="time"
                        value={submitTime}
                        onChange={handleSubmitTimeChange}
                        className="EditPageInputContainer"
                      />
                    </LocalizationProvider>
                  </section>
                </div>
              </>
            ) : (
              <></>
            )}
          </section>

          <section className="EditPageTitleContainer">
            <div className="EditPageInputTitleContainer">
              {pageLanguage === "EN" ? "Description" : "文章内容"}
            </div>
            <div>
              <ReactQuill
                modules={modules}
                theme="snow"
                value={
                  description !== ""
                    ? description
                    : note
                    ? note.description
                    : ""
                }
                onChange={handleDescriptionChange}
                className="CustomizeReactQuill"
              />
            </div>
          </section>

          <section className="EditPageSubmitButtonContainer">
            <button onClick={handleSave} className="EditPageSubmitButton">
              {pageLanguage === "EN" ? "PUBLISH" : "发 布"}
            </button>
          </section>
        </section>
      ) : (
        <section className="EditPageBodyContainer">Please Log In</section>
      )}

      <section className="EditPageFooterContainer">
        <FooterBar
          updatePageLanguageVariable={updatePageLanguageVariable}
          colorTheme={0}
          footerLanguage={pageLanguage}
        />
      </section>
    </article>
  );
}
